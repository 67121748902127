import './hero.css'
import suits from '../../assets/suits/main_bg1.png'

import home1 from '../../assets/suits/home1.jpg'

import suits1 from '../../assets/suits1.jpg'
import suits3 from '../../assets/suits/blezer.jpg'
import suits4 from '../../assets/suits/main_bg2.png'
import suits5 from '../../assets/suits/nav.jpg'
import suit56 from '../../assets/suits/suit6.jpg'

import emb from '../../assets/samples/bg.jpg'

import { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { useNavigate } from 'react-router-dom'
import { getState, getUser } from '../../Firebase/auth'
import Patti_home from './patti'
import Hero_text from './hero_text'

import icon from '../../assets/logo/icon.png'

import icon1 from '../../assets/logo/homeimg.jpg'

export default function Hero({scrollToElement}){

    const navigate = useNavigate()

    const [index, setIndex] = useState(0);

    
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    

    const data = [{image:home1, caption:"1", description:"first"},
                    {image:suits5, caption:"2", description:"second"},
                    {image:emb, caption:"3", description:"Third"} ]

    const [isMobile, setIsMobile] = useState(false)
                    
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 480); // Adjust the width as needed
        };

        // Initial check
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Clean up event listener on unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return(
        <div className="Hero">

            <img src={icon} className='icon_hero_smg'/>

            <img src={icon1} className='icon_hero_smg1'/>
            
            <div className='hero-image-container'>
                    <Carousel activeIndex={index} onSelect={handleSelect} fade>
                        {data.map((slide, i) => {
                            return (
                            <Carousel.Item>
                            <img
                            className="hero-img"
                            style={((i === 1 || i===0) && isMobile) ? {objectPosition:"-480px"}:{}}
                            src={slide.image}
                            alt="slider image"
                            />
                            
                        </Carousel.Item>
                            )
                        })}
                </Carousel>

            </div>

            {/* <div
                className='patti'
            >
                
                <label style={{"width":"fit-content"}} className='verti_center'>
                        {caps[active1]}
                </label>
                
            </div>
             */}
             {/* <Patti_home /> */}

            <div className='hero-full'></div>  
            <Hero_text scrollToElement = {scrollToElement}/>
        </div>
    )
}
