import { useEffect, useRef, useState } from "react";

// import nextArr from '../../assets/ALeft.png'
// import prevArr from '../../assets/ARight.png'

// import dummy from '../../assets/samples/body.png'
// import dummy1 from '../../assets/samples/BODY1.png'
// import dummy2 from '../../assets/samples/body2.png'

import new_body from '../../assets/Body/new_suit_body.webp'

import pant_body from '../../assets/Body/pant_body.png'

import formal_body from '../../assets/Body/new_formal.webp'


import kurta_body from '../../assets/Body/kurta_body.png'
import pajama_body from '../../assets/Body/pajama_body.png'

import aim from '../../assets/samples/anima.gif'
// import { dummyImg } from '../../Data'

import Tooltip from '@mui/material/Tooltip';

import Zoom from '@mui/material/Zoom';


import M from '../../assets/icon/magnifying.svg'
import War from '../../assets/icon/w.svg'
// import warning1 from '../../assets/icon/warning.svg'

import '../fabric.css'


import default_shirt1 from '../../assets/samples/shirt/SHIRT.png'
import default_shirt_formal from '../../assets/samples/shirt_1.png'
import default_pant_formal from '../../assets/Body/pant.png'
import sadri_def from '../../assets/Body/sadri_def.png'

import kurta_hand_n from '../../assets/Body/kurta_hand_n.png'
import kurta_hand_s from '../../assets/Body/kurta_hand_s.png'
import kurta_hand_k from '../../assets/Body/kurta_hand_k.png'


import kurta_hand_c from '../../assets/Body/kurta_hand_c.png'

import { checkCategory } from "../../Functions";


function ViewImages({category, sel, single, loading, setLoading, imgState, setImgState, type, isMobile, setState1, jodh, initial}) {
    // const [imgState, setImgState] = useState(0)
    const [Single, setSingle] = useState()
    const [state, setState] = useState(1)

    const [magnify, setMagnify] = useState(false)
    const [[x, y], setXY] = useState([0, 0])

    const [def_body, setDefBody] = useState(
        (category === "suit" || category === "blazer") ? new_body 
        : category === "formal" ? formal_body 
        : category === "shirt" ? formal_body 
        : category === "pant" ? formal_body 
        : (category === "kurta" || category === "sadri") ? kurta_body
        : new_body
        );
    
    const [def_shirt, set_def_shirt] = useState(
        (category === "suit" || category === "blazer") ? default_shirt1
        : category === "formal" ? default_pant_formal
        : category === "pant" ? default_shirt_formal
        : category === "shirt" ? default_pant_formal
        : category === "sadri" ? sadri_def
        : default_shirt1
    )

    const [hands, setHands] = useState(
        ((sel?.types && sel.types.includes("Coat") && imgState < 0) || (category === "sadri" && imgState === 0)) ? kurta_hand_c
        : sel?.types && sel.types.includes("Sadri") && imgState < 0 && sel?.style?.Kurta?.F[8] === "SC" ? kurta_hand_s
        : kurta_hand_n
    )
    
    /// to change hand
    useEffect(()=>{
        if(imgState<0){
            if( sel?.types && sel.types.includes("Sadri") && sel?.style?.Kurta?.F[8] === "SC" ){
                setHands( kurta_hand_s )
            } else if(sel?.types && sel.types.includes("Sadri") ) {
                setHands( kurta_hand_n )
            }
        }
    }, [sel])

    

    const magnifyStyle = (X, Y) => ({
        transform:`scale(3)`,
        left: `${X}%`,
        top: `${Y}%`
      });

    useEffect(()=>{ 
        // console.log("type received ", type)

        

        if(type === 1){
            // const p = ["Coat", "Vest", "Pant", "Shirt"]
            const p = sel.types
            // console.log("coming here to fetch single")
            let data = []
            p.forEach((typS)=>{
                
                data = [...data, ...sel["fabric"][typS]["single"]]
            })
            // console.log("data from profile", data)
            setSingle(data)
            setState1(data.length + 1)
        }else{
            setState(single.length + 1)
        }
    }, [])
    const counter = useRef(0);
    useEffect(()=>{
        if(!loading){
            counter.current = 0;
        }
    }, [loading])
    const imageLoaded = () => {
        counter.current += 1;
        let L = 0;
        console.log("called this : ", counter.current)
        if(imgState <= 0){
            if(!checkCategory(category)){
                if(category === "shirt"){
                    // L = 4;
                    L = sel["fabric"]["Shirt"].src.length + 2 
                } else if(category === "pant"){
                    L = 3 
                } else if(category === "formal") {
                    //// formal
                    L = 1 + sel["fabric"]["Shirt"].src.length + 1 + 1
                } else if(category === "blazer"){ 
                    L = 4 ;
                }
                else if(category === "sadri"){
                    L = 6
                }
                // console.log("this is happening")
            }
            else if(category === "suit"){
                if(sel && sel.types && sel.types.includes("Vest")){
                    L = sel.types.length + 3    
                }else{
                    L = sel.types.includes("Shirt") ? sel.types.length + 1 : sel.types.length + 2
                }
    
                if(jodh){
                    L = L-1;
                }
            } 
            else if(category === "kurta"){
                
                
                if(imgState === -1){
                    if(initial){
                        L = 5;
                    } else {
                        L = 2;
                    }
                    
                } else {
                    L = 7;
                }
                
            }
            
            // L = sel.types.length
        }else{
            if( sel.types.includes("Coat") && category === "kurta" && imgState === 4){
                L = 2;
                console.log("cuz of 1")
            } else if(sel?.types?.includes("Sadri") && category === "kurta" && imgState === 3){
                L = 2;
                console.log("cuz of 2")
            } else if(category === "kurta" && imgState === 2 && !(sel.types.includes("Coat") || sel.types.includes("Kurta")) ){
                L = 2;
                console.log("cuz of 3")
            } else {
                L = 1;
            }
        }
        
        if ( (counter.current >= L && (category != "shirt" && category != "formal" && category !="kurta" && category != "sadri") )
            ||( ((category === "shirt" || category === "formal") && Array.isArray(sel.fabric["Shirt"].src)) && counter.current >= L )
            || (((category === "kurta") && Array.isArray(sel.fabric["Kurta"].src)) && counter.current >= L )
            || (((category === "sadri") && Array.isArray(sel.fabric["Sadri"].src)) && counter.current >= L )
            
        ) {
            setLoading(false);
            counter.current = 0;
            console.log("Setting it false")
        }
    }

    useEffect(()=>{
            if(loading){
                setTimeout(()=>{
                    setLoading(false);
                    console.log("setting because of timeout")
                    counter.current = 0;
                }, 4000)
            }
        }, [loading])

    return ( 
        <div className="v-d-body" id="display_image"
            

            onClick={()=>{
                if(!isMobile){
                    setMagnify(!magnify)
                }
                
            }}

            onMouseLeave={()=>{
                setMagnify(0)
            }}

            onMouseMove={(e)=>{
                
                const element = document.getElementById("display_image")
                
                var rect = element.getBoundingClientRect();
                

                
                var x = e.pageX - rect.left; //x position within the element.
                var y = e.pageY - rect.top;
                const pX = ((x)/rect.width)*100
                const pY=  ((y)/rect.height)*100
                // // console.log(rect)
                // // console.log("X:", x, "Y:", y, "max:", e.pageX, e.pageY)

                const left = Math.round(((-3)*(pX) / 5) + 30)
                const top = Math.round((100 - 2*pY))
                
                // // console.log(left, top)
                setXY([left, top])

                }}
            
            
        >

            <div style={{"position":"absolute", "zIndex":"100"}}>
                <Tooltip TransitionComponent={Zoom} title="Visible Images are Renderd. It May be different from actual product. Open Fabric info to see real images of Fabric ">
                    {/* <Button>Zoom</Button> */}
                    <img src={War} style={{"width":"20px", "opacity":"1"}}/>
                </Tooltip>
            </div>
            {!isMobile && <div style={{"position":"absolute","top":"40px", "zIndex":"100"}}>
                <Tooltip TransitionComponent={Zoom} title="Click on the Image to Zoom">
                    {/* <Button>Zoom</Button> */}
                    <img src={M} style={{"width":"20px", "opacity":"1"}}/>
                </Tooltip>
            </div>}

            
                    

            {/* https://stackoverflow.com/questions/56902522/react-show-loading-spinner-while-images-load */}
            {(loading) && (<div className='f-loading' 
                style={{background:"#E5E5E5"}}  
            >
                {/* <div>
                <img src={logo} style={{"width":"40px", "position":"absolute"}} /> */}
                <img alt src={aim} onerror="this.src='https://d1fufvy4xao6k9.cloudfront.net/images/garment/loading.gif'"/>
                {/* </div> */}
            </div>)}
            {sel && sel.types && imgState < 0 ? (

                <>
                    <img src={def_body} className={
                        `hori_center v-display-img-full dummy first_img_mob
                            ${    (category === 'shirt' || category === "blazer" || category === "sadri") ? `v-display-img-${category}`
                                : category === 'pant' ? 'v-display-img-pant'
                                :''}
                        `
                    }
                        onLoad={imageLoaded}
                        style={
                            magnify ? magnifyStyle(x, y) : {}
                        }
                    />
                    
                    { category !="kurta" && <img src={def_shirt} className=
                        {`hori_center v-display-img-full first_img_mob dummy
                            ${isMobile ? 'mobile-image1' : ''} 
                            ${jodh ? `jodh_shirt`:''} 
                            ${    (category === 'shirt' || category === "blazer" || category === "sadri") ? `v-display-img-${category} def_pant`
                                : category === 'pant' ? 'v-display-img-pant'
                                : category === 'formal' ? 'v-display-default'
                                : category === "kurta" ? 'display_none' 
                                : '' }
                        `}
                        onLoad={imageLoaded}
                        style={
                            // imgState > 0 || sel.types.includes("Shirt") ?{"display":"none"}:
                            magnify ? magnifyStyle(x, y) : {} 
                        }
                    />}
                    { category === "blazer" && 
                        <img src={"https://firebasestorage.googleapis.com/v0/b/maviinvi.appspot.com/o/Suits%2FPant%2FPANT-1726940263526?alt=media&token=080a27a0-2fa6-4428-a573-280768af3313"} className={
                            `hori_center v-display-img-full dummy first_img_mob
                                v-display-img-blazer`
                        }
                            onLoad={imageLoaded}
                            style={
                                magnify ? magnifyStyle(x, y) : {}
                            }
                        />
                    }
            
                    {sel.types.map((val, key)=>{
                        return(
                            <> { imgState < 0 && Object.hasOwn(sel.fabric[val], "default") ? (
                                <>
                                {sel.fabric[val].default && sel.fabric[val]["default"].map((value_img)=>{
                                    return(
                                        <img src={ value_img }
                                            onLoad={imageLoaded}
                                            style={ magnify ? {...magnifyStyle(x, y), ...{"zIndex":50-sel.fabric[val]["pos"]}} : 
                                                {"zIndex":50-sel.fabric[val]["pos"]} } 

                                            className=
                                            {`v-display-img-full first_img_mob hori_center this_is_def
                                                ${isMobile ? 'mobile-image2' : ''}
                                                ${   (category === 'shirt' || category === "blazer" || category === "sadri") ? `v-display-img-${category}`
                                                    :category === 'pant' ? 'v-display-img-pant'
                                                    :''}
                                            `}
                                        />
                                    )
                                })}
                            </>
                                )
                                : ( ((imgState === 0 && !( category === "kurta" &&  (val === "Coat" || val === "Sadri")) ) || (imgState === -1)) && <>
                                
                                    { sel && sel.fabric && Object.hasOwn(sel.fabric, val) && Array.isArray( sel.fabric[val]["src"] ) ? 
                                            <>
                                                { sel.fabric[val]["src"].map((values, keys)=>{
                                                    return(
                                                        <img src={ values }
                                                        onLoad={imageLoaded}
                                                        style={ magnify ? {...magnifyStyle(x, y), ...{"zIndex":50-sel.fabric[val]["pos"]}} : 
                                                            {"zIndex":50-sel.fabric[val]["pos"]} } 

                                                        className=
                                                        {`v-display-img-full first_img_mob hori_center 
                                                            ${isMobile ? 'mobile-image2' : ''}
                                                            ${(category === 'shirt' || category === "blazer" || category === "sadri") ? `v-display-img-${category}`
                                                                :category === 'pant' ? 'v-display-img-pant'
                                                                :''}
                                                        `}
                                                        />
                                                    )
                                                }) }
                                            </>
                                    : 
                                        <img src={ sel && sel.fabric && Object.hasOwn(sel.fabric, val) ? sel.fabric[val]["src"] : "this" }
                                        onLoad={imageLoaded}
                                        style={ magnify ? {...magnifyStyle(x, y), ...{"zIndex":50-sel.fabric[val]["pos"]}} : 
                                            {"zIndex":50-sel.fabric[val]["pos"]} } 

                                        className=
                                        {`v-display-img-full first_img_mob hori_center 
                                            ${isMobile ? 'mobile-image2' : ''}
                                            ${(category === 'shirt' || category === "blazer" || category === "sadri")? `v-display-img-${category}`
                                                :category === 'pant' ? 'v-display-img-pant'
                                                :''}
                                            ${imgState ===0 && category === "kurta" && (val === "Coat" || val === "Sadri")?'display_none':''}
                                        `}
                                        />
                                    }
                                </>)
                                }
                            </>
                            
                        )
                    })}
                    {
                    ((category === "kurta" && (sel?.types.includes("Coat") || sel?.types.includes("Sadri")))) && <img src={hands} className={
                        `hori_center v-display-img-full dummy first_img_mob hands
                            ${    (category === 'shirt' || category === "blazer" || category === "sadri") ? `v-display-img-${category}`
                                : category === 'pant' ? 'v-display-img-pant'
                                :''}
                        `
                    }
                        onLoad={imageLoaded}
                        style={
                            magnify ? magnifyStyle(x, y) : {}
                        }
                    />}
                </>
                
                
            )
            : sel && sel.types && imgState === 0 ?  (

                <>
                    <img src={def_body} className={
                        `hori_center v-display-img-full dummy first_img_mob
                            ${    (category === 'shirt' || category === "blazer" || category === "sadri") ? `v-display-img-${category}`
                                : category === 'pant' ? 'v-display-img-pant'
                                :''}
                        `
                    }
                        onLoad={imageLoaded}
                        style={
                            magnify ? magnifyStyle(x, y) : {}
                        }
                    />
                    {
                     (category === "sadri") && <img src={hands} className={
                        `hori_center v-display-img-full dummy first_img_mob hands
                            ${    (category === 'shirt' || category === "blazer" || category === "sadri") ? `v-display-img-${category}`
                                : category === 'pant' ? 'v-display-img-pant'
                                :''}
                        `
                    }
                        onLoad={imageLoaded}
                        style={
                            magnify ? magnifyStyle(x, y) : {}
                        }
                    />}
                    
                    { category !="kurta" && <img src={def_shirt} className=
                        {`hori_center v-display-img-full first_img_mob dummy
                            ${isMobile ? 'mobile-image1' : ''} 
                            ${jodh ? `jodh_shirt`:''} 
                            ${    (category === 'shirt' || category === "blazer" || category === "sadri") ? `v-display-img-${category} def_${category}`
                                : category === 'pant' ? 'v-display-img-pant'
                                : category === 'formal' ? 'v-display-default'
                                : category === "kurta" ? 'display_none' 
                                : '' }
                        `}
                        onLoad={imageLoaded}
                        style={
                            // imgState > 0 || sel.types.includes("Shirt") ?{"display":"none"}:
                            magnify ? magnifyStyle(x, y) : {} 
                        }
                    />}
                    { category === "blazer" && 
                        <img src={"https://firebasestorage.googleapis.com/v0/b/maviinvi.appspot.com/o/Suits%2FPant%2FPANT-1726940263526?alt=media&token=080a27a0-2fa6-4428-a573-280768af3313"} className={
                            `hori_center v-display-img-full first_img_mob
                                v-display-img-blazer def_pant`
                        }
                            onLoad={imageLoaded}
                            style={
                                magnify ? magnifyStyle(x, y) : {}
                            }
                        />
                    }
            
                    {sel.types.map((val, key)=>{
                        return(
                            <> { imgState < 0 && Object.hasOwn(sel.fabric[val], "default") ? (

                                    <>
                                        {sel.fabric[val]["default"].map((value_img)=>{
                                            return(
                                                <img src={ value_img }
                                                    onLoad={imageLoaded}
                                                    style={ magnify ? {...magnifyStyle(x, y), ...{"zIndex":50-sel.fabric[val]["pos"]}} : 
                                                        {"zIndex":50-sel.fabric[val]["pos"]} } 

                                                    className=
                                                    {`v-display-img-full first_img_mob hori_center this_is_def
                                                        ${isMobile ? 'mobile-image2' : ''}
                                                        ${   (category === 'shirt' || category === "blazer" || category === "sadri") ? `v-display-img-${category}`
                                                            :category === 'pant' ? 'v-display-img-pant'
                                                            :''}
                                                    `}
                                                />
                                            )
                                        })}
                                    </>
                                    
                                )
                                : ( ((imgState === 0 && !( category === "kurta" &&  (val === "Coat" || val === "Sadri")) ) || (imgState === -1)) && <>
                                
                                    { sel && sel.fabric && Object.hasOwn(sel.fabric, val) && Array.isArray( sel.fabric[val]["src"] ) ? 
                                            <>
                                                { sel.fabric[val]["src"].map((values, keys)=>{
                                                    return(
                                                        <>
                                                        <img src={ values }
                                                        onLoad={imageLoaded}
                                                        style={ magnify ? {...magnifyStyle(x, y), ...{"zIndex":50-sel.fabric[val]["pos"]}} : 
                                                            {"zIndex":50-sel.fabric[val]["pos"]} } 

                                                        className=
                                                        {`v-display-img-full first_img_mob hori_center 
                                                            ${isMobile ? 'mobile-image2' : ''}
                                                            ${(category === 'shirt' || category === "blazer" || category === "sadri") ? `v-display-img-${category}`
                                                                :category === 'pant' ? 'v-display-img-pant'
                                                                :''}
                                                            ${imgState ===0 && category === "kurta" && (val === "Coat" || val === "Sadri")?'display_none':''}
                                                        `}
                                                        />
                                                        {category === "kurta" && val === "Kurta" && keys ===0 && 
                                                          <img src={ kurta_hand_k }
                                                          onLoad={imageLoaded}
                                                          style={ magnify ? {...magnifyStyle(x, y), ...{"zIndex":50-sel.fabric[val]["pos"]}} : 
                                                              {"zIndex":50-sel.fabric[val]["pos"]} } 
  
                                                          className=
                                                          {`v-display-img-full first_img_mob hori_center 
                                                              ${isMobile ? 'mobile-image2' : ''}
                                                              ${(category === 'shirt' || category === "blazer" || category === "sadri") ? `v-display-img-${category}`
                                                                  :category === 'pant' ? 'v-display-img-pant'
                                                                  :''}
                                                              ${imgState ===0 && category === "kurta" && (val === "Coat" || val === "Sadri")?'display_none':''}
                                                          `}
                                                          />  

                                                        }
                                                        </>
                                                    )
                                                }) }
                                            </>
                                    : 
                                        <img src={ sel && sel.fabric && Object.hasOwn(sel.fabric, val) ? sel.fabric[val]["src"] : "this" }
                                        onLoad={imageLoaded}
                                        style={ magnify ? {...magnifyStyle(x, y), ...{"zIndex":50-sel.fabric[val]["pos"]}} : 
                                            {"zIndex":50-sel.fabric[val]["pos"]} } 

                                        className=
                                        {`v-display-img-full first_img_mob hori_center 
                                            ${isMobile ? 'mobile-image2' : ''}
                                            ${(category === 'shirt' || category === "blazer" || category === "sadri")? `v-display-img-${category}`
                                                :category === 'pant' ? 'v-display-img-pant'
                                                :''}
                                            ${imgState ===0 && category === "kurta" && (val === "Coat" || val === "Sadri")?'display_none':''}
                                        `}
                                        />
                                    }
                                </>)
                                }
                            </>
                            
                        )
                    })}
                </>
                
                
            )
            : imgState > 0 ?
            (<>

            {/* 1. Coat
            2. vest
            3. Pant
            4. Shirt */}
            
                {/* <img src={s2} className='v-display-img-full' onLoad={imageLoaded}/>
                <img src={s5} className='v-display-img-full' onLoad={imageLoaded}/>
                <img src={s3} className='v-display-img-full' onLoad={imageLoaded}/>
                <img src={s1} className='v-display-img-full' onLoad={imageLoaded}/> */}
                {/* {type && type === 0 ? (<>
                
                </>)
                : (<></>)
                } */}
                { type === 0 && single && single[imgState-1]["typ"] === 0 ? (
                    <img src={single[imgState-1]["src"]} className={`v-display-img-full hori_center ${isMobile ? 'mobile-image' : ''} 
                            ${isMobile && (  (category === "formal" && imgState === 1) || (category === "suit" && imgState ===3)) ? "shirt_mob_scale" : ""}
                            ${isMobile && (category === "kurta" && imgState === 1 ) ? "kurta_mob_scale" : ""}
                            ${isMobile && (category === "kurta" && sel?.types.includes("Sadri") && imgState === 3 ) ? 'sadri_mob_scale' : ''}
                            ${isMobile && 
                                ((category === "kurta" && sel?.types.includes("Sadri") && imgState === 2 ) || (category === "kurta" && sel?.types.includes("Coat") && imgState === 4) ) ? 'pajama_mob_scale' : ''}
                            ${isMobile && (category === "kurta" && !sel?.types.includes("Sadri") && !sel?.types.includes("Coat") && imgState === 2 ) ? 'pajama_mob_scale' : ''}
                        `}
                            
                        onLoad={imageLoaded}

                        style = {magnify ? magnifyStyle(x, y):{}}
                    />
                ) : (<>
                    {type===0 && single && single[imgState-1]["src"].map((imgV, imgKey)=>{
                        return(
                            <img src={imgV} className={`v-display-img-full hori_center ${isMobile ? 'mobile-image' : ''} 
                            ${isMobile && (  (category === "formal" && imgState === 1) || (category === "suit" && imgState ===3)) ? "shirt_mob_scale" : ""}
                            ${isMobile && (category === "kurta" && imgState === 1 ) ? "kurta_mob_scale" : ""}
                            ${isMobile && (category === "kurta" && sel?.types.includes("Sadri") && imgState === 3 ) ? 'sadri_mob_scale' : ''}
                            ${isMobile && 
                                ((category === "kurta" && sel?.types.includes("Sadri") && imgState === 2 ) || (category === "kurta" && sel?.types.includes("Coat") && imgState === 4) ) ? 'pajama_mob_scale' : ''}
                            ${isMobile && (category === "kurta" && !sel?.types.includes("Sadri") && !sel?.types.includes("Coat") && imgState === 2 ) ? 'pajama_mob_scale' : ''}
                        `}
                                onLoad={imageLoaded}

                                style = {magnify ? magnifyStyle(x, y):{}}
                            />

                            
                        )
                    })

                    }
                </>)

                }

                {type && type === 1 ? (<>
                    {Single && Single[imgState-1]["typ"] === 0 ? (
                    <img src={Single[imgState-1]["src"]} className={`v-display-img-full ${isMobile ? 'mobile-image' : ''} 
                            ${isMobile && (  (category === "formal" && imgState === 1) || (category === "suit" && imgState ===3)) ? "shirt_mob_scale" : ""}
                            ${isMobile && (category === "kurta" && imgState === 1 ) ? "kurta_mob_scale" : ""}
                            ${isMobile && (category === "kurta" && sel?.types.includes("Sadri") && imgState === 3 ) ? 'sadri_mob_scale' : ''}
                            ${isMobile && 
                                ((category === "kurta" && sel?.types.includes("Sadri") && imgState === 2 ) || (category === "kurta" && sel?.types.includes("Coat") && imgState === 4) ) ? 'pajama_mob_scale' : ''}
                            ${isMobile && (category === "kurta" && !sel?.types.includes("Sadri") && !sel?.types.includes("Coat") && imgState === 2 ) ? 'pajama_mob_scale' : ''}

                        `}
                        style={loading ? {"display":"none"} : {} }
                        onLoad={imageLoaded}
                    />
                ) : (<>
                    {type === 1 && Single && Single[imgState-1]["src"].map((imgV, imgKey)=>{
                        return(
                            <img src={imgV} className={`v-display-img-full ${isMobile ? 'mobile-image' : ''}
                            ${isMobile && (  (category === "formal" && imgState === 1) || (category === "suit" && imgState ===3)) ? "shirt_mob_scale" : ""}
                            ${isMobile && (category === "kurta" && imgState === 1 ) ? "kurta_mob_scale" : ""}
                            ${isMobile && (category === "kurta" && sel?.types.includes("Sadri") && imgState === 3 ) ? 'sadri_mob_scale' : ''}
                            ${isMobile && 
                                ((category === "kurta" && sel?.types.includes("Sadri") && imgState === 2 ) || (category === "kurta" && sel?.types.includes("Coat") && imgState === 4) ) ? 'pajama_mob_scale' : ''}
                            ${isMobile && (category === "kurta" && !sel?.types.includes("Sadri") && !sel?.types.includes("Coat") && imgState === 2 ) ? 'pajama_mob_scale' : ''}
                        `}
                                style={loading ? {"display":"none"} : {} }
                                onLoad={imageLoaded}
                            />
                        )
                    })

                    }
                </>)
                }
                </>)
                : (<></>)
                }
                
                
            </>)
            : (<></>)
            
            }
            
            

        </div> 
    );
}

export default ViewImages;


///
// presets page not in center for phone

/// choose presets from admin panel to display on home page

// add now button on saved mesasuremenrts

// clone a cloth selected option

// content in websites

// cart item delete

// get help custom measurements , animation with good text in custom measuremnts

// video of explaore in home page not in center in phone

// collaborations not smooth in infinite

// scrolling a bit much on style svgs 