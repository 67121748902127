import { useEffect, useRef, useState } from 'react';
import cart from '../assets/icon/addCart.png'

import aim from '../assets/samples/anima.gif'
import { useNavigate } from 'react-router-dom';

import customize_icon from '../assets/icon/customize.svg'


function P_card({item}) {


    const [isMobile, setIsMobile] = useState(window.innerWidth < 480);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 480);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [load, setLoad] = useState(false)

    
    const [uni, setUni] = useState()

    const [Str, setStr] = useState("")

    useEffect(()=>{
        var a = [];
        var S = "";
        item.fabrics.forEach(element => {
            if(!a.includes(element.type)){
                a.push(element.type)
                S+= "/"+element.type+"=brand?"+element.brand+"&fabric?"+element.name
            }

        });
        setStr(S)
        setUni(a)
    }, [])
    const navigate = useNavigate()

    const counter = useRef(0);
    const imageLoaded = () => {
        counter.current += 1;
        let L = 1;
        
        if (counter.current >= L) {
            setLoad(false);
            counter.current = 0;
        }
    }
    return ( 
    <div className="p-item-card">
        <a href={`/products/${item.category}/${item.name.replace(/ /g, "-")}/${item.doc}`} style={{textDecoration:"none", color:"black"}}>
        <div className="preset_img"
            onClick={()=>{
                window.scrollTo(0, 0);
                navigate(`/products/${item.category}/${item.name.replace(/ /g, "-")}/${item.doc}`, {state: { sel: item } });
            }}
        >
            {(load) && 
            <div style={{width:"100%", height:"100%", backgroundColor:"white", position:"absolute"}}>
                <img src={aim} style={{width:"70px"}} className='both_center'/>
            </div>
            }

            {!load && <img src={item.src} type="button"
                className='preset_main_img'
                onLoad={imageLoaded}
            />}

            {item.customize && 
            
            <img
                src={customize_icon}
                className='cutomize_icon'
            />}
        </div>
        </a>
        
        <div className="presets_bottom" type="button"
            onClick={()=>{
                // navigate("/products", { state: { sel: item } });
            }}
        >
            <div style={{position:"relative"}}>
                <a href={`/products/${item.category}/${item.name.replace(/ /g, "-")}/${item.doc}`} style={{textDecoration:"none", color:"black"}}>
                <label className="txt-font1 preset_name "
                type="button"
                
                    style={isMobile ? {width:"100%"} : {width:"100%"}}
                    onClick={()=>{
                        window.scrollTo(0, 0);
                        navigate(`/products/${item.category}/${item.name.replace(/ /g, "-")}/${item.doc}`, {state: { sel: item } });
                    }}
                >
                    {item.name}
                </label>
                </a>
                <a href="/measurement" style={{textDecoration:"none"}}>
                {/* <img src={cart} style={{width:"25px", position:"absolute", right:"0"}} type="button"
                    onLoad={imageLoaded}
                    onClick={()=>{
                        sessionStorage.setItem('sel',JSON.stringify({...item, ...{item_type:1}}))
                        window.scrollTo(0, 0);
                        navigate("/measurement")
                        console.log("Clicking on cart")
                    }}
                /> */}
                </a>
            </div>
            {/* <label>
                {uni && uni.map((typ_val, k)=>{
                    return(<span>{typ_val}  {k<uni.length-1 ?<>,</>:<></>} </span>)
                })}
            </label> */}

            <a href={`/products/${item.category}/${item.name.replace(/ /g, "-")}/${item.doc}`} style={{textDecoration:"none", color:"black"}}>
            <div
                onClick={()=>{
                    window.scrollTo(0, 0);
                    navigate(`/products/${item.category}/${item.name.replace(/ /g, "-")}/${item.doc}`, {state: { sel: item } });
                }}
            > 

                
                <div style={{marginBottom:"10px"}}>
                {item.discount && Number(item.discount)>0 ?
                    <>
                    <span className='txt-font1 preset_name'style={{fontSize:"1rem"}}>{(Number(item.price) - Number(item.price)*(Number(item.discount)/100)).toLocaleString('en-IN', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                        style: 'currency',
                        currency: 'INR'
                    })}</span>
                    &nbsp;&nbsp;&nbsp;
                    <span style={{fontSize:"0.7rem", opacity:"1", textDecoration:"line-through"}}>
                        
                        {
                            Number(item.price).toLocaleString('en-IN', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                                style: 'currency',
                                currency: 'INR'
                            })
                        }  
                    </span>
                    &nbsp;&nbsp;
                    <label style={{backgroundColor:"green", "color":"white", padding:"1px 5px", margin:"0", height:"fit-content"}} 
                        className='txt-small'
                    > {item.discount} % off</label>

                    </>
                    : <>
                    <span className='txt-font1'>
                        {
                            Number(item.price).toLocaleString('en-IN', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                                style: 'currency',
                                currency: 'INR'
                            })
                        }  
                    </span>
                    {/* {isMobile && <><br/><br/></>} */}
                    </>
                }
                </div>

                Est. Delivery : { (new Date(  (new Date().setDate( (new Date()).getDate() +13 )) )).toDateString() }

                <div style={{color:"green"}} >Extra 5% off on Prepaid Orders</div>
            
            </div>
            </a>

            {/* {item.name.length < 20 ? <p className='txt-font' style={{height:"1.75rem"}} >  </p> : <></>} */}
        </div>

    </div> );
}

export default P_card;