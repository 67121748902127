import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getState } from "../Firebase/auth"; 
import { AuthContext } from "../Firebase/AuthProvider";

import secure from '../assets/icon/secure.svg'

function Cost({change, page, setPage, setTerms, pay , displayRazorpay, setLoading }) {
    const navigate = useNavigate();

    const [cked,setCked] =  useState(0)

    const { user } = useContext(AuthContext);

    const [data, setData] = useState( JSON.parse(localStorage.getItem("sel")))
    const [price, setPrice] = useState();

    useEffect(()=>{
        let Data = JSON.parse(localStorage.getItem("sel"))
        setData(Data)
        let p = 0;
        let fab = 0;
        let discount = 0;
        if(Data){
        
            for(let i=0;i<Data.length;i++){
                if(! (Object.keys(Data[i]).includes("item_type") && Data[i].item_type === 0) ){

                    if( !Object.keys(Data[i]).includes("item_type") ){
                        // p= p + Number(Data[i].price) - Number(Data[i].discount)
                        p= p + Number(Data[i].price)
                        discount += Number(Data[i].discount)
                    } else {
                        // p = p+ Number(Data[i].price) - (Number(Data[i].price) * Number(Data[i].discount)/100)
                        p = p+ Number(Data[i].price)
                        discount += (Number(Data[i].price) * Number(Data[i].discount)/100)
                    }
                    

                }else{
                    fab=  fab + Data[i].items.length
                }
            }
        p = Number(p)

        let extra = 0;
        if(fab>1){
            extra = 250
        }else{
            extra = (fab*100)
        }


        setPrice([{"typ":"Your Items total", "value":(p) + Number(extra) , "c":0},

                {"typ":"Discount", "value": `${Number(-1*Number(discount))}`, "c":1},
                {"typ":"Shipping", "value": "FREE", "c":1},
                {"typ":"Total", "value": p + Number(extra)-discount , "c":0  } ]) 
            

                if(pay === 0){
                    let total = (p) + Number(extra)
        
                    setPrice([ {"typ":"Your Items total", "value":(p) + Number(extra) , "c":0}, 
                        {"typ":"Discount", "value": `${Number(-1*Number(discount))}`, "c":1},
                        {"typ":"Offer (prepaid order)", "value":  (total-discount)/20 , "c":1},
                        {"typ":"Shipping", "value": "FREE", "c":1},
                        {"typ":"Pay now", "value":   total - (total-discount)/20 - discount, "c":0 }])
                }
        }

        
    }, [change])


    useEffect(()=>{
        if(pay === 2 && price){

            setPrice([ 
                price[0], price[1],  
                {"typ":"Total amount", "value": price[0].value+ Number(price[1].value) , "c":0 },
                {"typ":"Token Amount (COD)", "value": Number(500) , "c":0},
                {"typ":"Pay at Delivery", "value": price[0].value+Number(price[1].value)-500 , "c":0},
                {"typ":"Shipping", "value": "FREE", "c":1},
                {"typ":"Pay now", "value": Number(500), "c":0}
            ])

        }else if(pay != -1 && price){

            
            let total = price[0].value + Number(price[1].value)

            setPrice([ price[0], price[1],
                {"typ":"Offer (prepaid order)", "value":  total/20 , "c":1},
                {"typ":"Shipping", "value": "FREE", "c":1},
                {"typ":"Pay now", "value":   total - total/20, "c":0 }])

        }
    }, [pay])

    const getNumebr=(p)=>{

        if(typeof p === "number"){
            p = Number(p)
        }
        return(
            p.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
            })
        )

    }

    return ( 
    <div className="cost cost-checkout"   
        style={data && data.length > 0 ?{}:{display:"none"}}
    >   
        <div className="secure" > <img src={secure}/> <label> 100% Secure </label> </div>

        { data && data.length > 0 && <>
        <div className="cost-des">
            <div style={{"width":"100%", "textAlign":"center"}}>
                <div className="hurray">Hurray !! your first Suit Order</div>
                <div className="free" style={{"color":"green"}}>You got free shipping</div>
            </div>
            <br/>
            <div className="txt-font1 subt">Subtotal {data.length}</div>
            
            {price && price.map((val, key)=>{
                return( 
                    <div className={key === price.length-1 ? "cost-row1 txt-font1 colorr" : "cost-row"} 
                    style={ val["c"] >= 1 ? {color:"#00a31b"} : {}}
                    >
                        <div className="c-left"  >{val["typ"]}</div>
                        <div className="c-right"
                        >   {getNumebr( val["value"] ) }</div>
                    </div>
                )
            })}

            {/* <div className="cost-row1 txt-font1">
                <div className="c-left">Total</div>
                <div className="c-right">$7000</div>
            </div> */}
        </div>
        
        {pay === -1 && 
        <div className="terms_include FIT_W"
            style={cked === -1 ? {borderBottom:"rgb(0, 0, 0, 0.3) 1px solid"} : {}}
        > 
            <input type="checkbox" id="terms_check"
                // checked={ pay != null ? true: false}      
                value={"on"}

                onChange={(e)=>{
                    if(e.target.checked){
                        setCked(1)
                        // console.log(e.target.checked)
                    } else{
                        setCked(0)
                    }
                }}
            />  &nbsp;
            

            <span
                style={cked === -1 ? {"color":"red"} : {}}
            >Agree to</span> <button className="terms" onClick={()=>{setTerms((prev)=>!prev) }}>Terms and conditions</button>
        </div> 
        }

        {page == 0 && <button className="Butt1 hori_center checkout-butt"
            style={{"position":"relative", "marginTop":"20px"}}
            onClick={()=>{

                if(cked === 1){

                
                    setPage(1)
                    // const currOrder =  JSON.parse(localStorage.getItem("sel"))
                    // localStorage.setItem("sel", JSON.stringify({...currOrder, ...{"breakUp":price}}))

                    if(user){

                    }else{
                        navigate("/login", {state:{from:"cart/1"}})
                    }
                } else {
                    setCked(-1)
                }
                
            }}

        >
            <span>Checkout</span>
        </button>}

        {pay!= -1 && <button className="Butt1 hori_center checkout-butt"
            style={{"position":"relative", "marginTop":"20px"}}
            onClick={()=>{

                const address =  JSON.parse( window.localStorage.getItem("a") )

                
                
                const data = {
                    name: address["delivery"]["name"],
                    phone : address["delivery"]["phone"],
                    items : JSON.parse( window.localStorage.getItem("sel") ).length,
                    price : Math.floor(price[ price.length - 1 ].value)
                }

                console.log(data)
                
                
                setLoading(true)
                displayRazorpay(data, 1).then((data)=>{
                    if(data === -1){
                        console.log("Cannot process the request now")
                        setLoading(false)
                    }
                    console.log("opened")
                })
            }}

        >
            <span>  {pay === 2 ? "Order Now" : "Pay Now"}  </span> 
        </button>}
        </> }
    </div> 

    );
}

export default Cost;