import './hoverComp.css'

function Measurement_hover({style1, style2, index }) {

    const data = [
        {
            title:"Custom Measurements",
            des:"Measuring for your perfect fit has never been easier. Follow our simple step-by-step guide to measure yourself accurately at home. Enjoy a hassle-free process that ensures a perfectly tailored outfit for you."
        },
        {
            title:"Choose From Size Chart",
            des:"Easily find your ideal fit with our detailed size chart, designed to match different body types. Compare your measurements with our standard sizes to pick the best fit. No guesswork, just a confident and comfortable choice every time."
        },
        {
            title:"Saved Measurements",
            des:"Reuse your saved measurements from previous orders for a seamless shopping experience. No need to measure again—just select your stored details, and we’ll craft your outfit accordingly. Perfect for quick and convenient custom clothing orders."
        },
        {
            title:"Clone a cloth",
            des:"Have a favorite outfit that fits you perfectly? Send us that piece, and we’ll replicate its exact fit and comfort for your new order. Enjoy the same perfect fit without the hassle of taking new measurements."
        }
    ]
    return ( 
    <div className="measurement_hover_comp"
        style={style1}
    >
        <div className="m_data_comp">
            <div style={{height:"fit-content"}} className='verti_center'>
            <div className="txt-font"> {data[index].title} </div>
            <div className='txt-font1' style={{marginTop:"10px"}}> {data[index].des} </div>
            </div>
        </div>
        <div className="m_hook">
            <div className="m_hook_wrapper">
                <div className={style2.class} style={style2.style} ></div>
            </div>
        </div>


    </div> );
}

export default Measurement_hover;