import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import React from 'react';
import Login from "./Auth";
import HomePage from "./Homepage";
import PrivateRoute from "./privateRoute";
import NavBar from "./Navbar";
import Fabric from "./Fabric";
import Checkout from "./Checkout";
import Profile from "./Checkout/profile";
import Presets_main from "./Presets";
import Preset_item from "./Presets/Preset-item";
import Feel_fabric from "./Fabric/Feel";
import Appointment from "./Contact/appointment";
import Measurement from "./Measurements";
import Contact from "./Contact";
import Terms_C from "./Contact/Terms_C";
import Foot from "./footer";
import Payments_page from "./Checkout/Payment/index";
import Order_page from "./Presets/Order_page";


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <div>
              <NavBar flag={3} />
                <HomePage />
              
                </div>
      },
      {
        path: "/profile",
        element: (
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          )
      },
      {
        path:"/login",
        element:<>
            <NavBar flag={1} />
            <Login/>
        </>
      },
      {
        path:"/custom/:category/:id?/:process?",
        element:
        <React.Suspense fallback = "Loading...">
            <Fabric/>
        </React.Suspense>
      },
      {
        path:"/cart/:id?",
        element:
        <React.Suspense fallback = "Loading...">
            <>
                <NavBar flag={1} />
                <Checkout/>
            </>
        </React.Suspense>
      },
      {
        path:"/products/:cloth?",
        element:
        <React.Suspense fallback = "Loading...">
            <>
                <NavBar flag={1} />
                <Presets_main/>
            </>
        </React.Suspense>
      },
      {
        path:"/products/:catr/:name/:id/:des?",
        element:
        <React.Suspense fallback = "Loading...">
            <>
                <NavBar flag={1} />
                <Preset_item/>
                <Foot/>
            </>
        </React.Suspense>
      },
      {
        path:"/feel",
        element:
        <React.Suspense fallback = "Loading...">
            <>
                <NavBar flag={1} />
                <Feel_fabric/>
            </>
        </React.Suspense>
      },
      {
        path:"/reachus",
        element:
        <React.Suspense fallback = "Loading...">
            <>
                <NavBar flag={1} />
                <Appointment/>
            </>
        </React.Suspense>
      },
      {
        path:"/measurement/:name?",
        element:
        <React.Suspense fallback = "Loading...">
            <>
                <NavBar flag={1} />
                <Measurement/>
            </>
        </React.Suspense>
      },
      {
        path:"/contact",
        element:
        <React.Suspense fallback = "Loading...">
            <>
                <NavBar flag={0} />
                <Contact/>
            </>
        </React.Suspense>
      },
      {
        path:"/terms",
        element:
        <React.Suspense fallback = "Loading...">
            <>
                <NavBar flag={1} />
                <Terms_C/>
            </>
        </React.Suspense>
      },
      {
        path:"/checkout/payment",
        element:
        <React.Suspense fallback = "Loading...">
            <>
                <NavBar flag={1} />
                <Payments_page/>
            </>
        </React.Suspense>
      },
      {
        path:"/order/:id",
        element:
        <React.Suspense fallback = "Loading...">
            <>
                <NavBar flag={1} />
                <Order_page/>
            </>
        </React.Suspense>
      }
      
    ]
  }
]);

export default router;
