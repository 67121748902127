import React, { useMemo } from 'react';


// import img1 from '../assets/suits/blezer.jpg'
// import img2 from '../assets/suits/jodhpuri.jpg'
// import img3 from '../assets/suits/kurta.jpg'
// import img4 from '../assets/suits/pant.jpg'
// import img5 from '../assets/suits/pant_shirt.jpg'
// import img6 from '../assets/suits/sherwani.jpg'
// import img7 from '../assets/suits/shirt.jpg'
// import img8 from '../assets/suits/suit.jpg'
// import img9 from '../assets/suits/uniform.jpg'
// import img10 from '../assets/suits/vest_coat.jpg'

import Hero from './Home/hero';

import suit from '../assets/samples/Types/suit.jpg'
import shirt_pant from '../assets/samples/Types/shirt_pant.jpg'
import blazer from '../assets/samples/Types/blazer.jpg'
import kurta from '../assets/samples/Types/kurta.jpg'
import uniform from '../assets/samples/Types/uniform.jpg'
import shirt from '../assets/samples/Types/shirt.png'
import pant from '../assets/samples/Types/pant.jpg'
import sherwani from '../assets/samples/Types/sherwani.jpg'
import jubba from '../assets/samples/Types/jubba.jpg'
import tshirt from '../assets/samples/Types/tshirt.png'
import jackets from '../assets/samples/Types/jacket.png'
import longCoat from '../assets/samples/Types/long_coat.png'




// const Gallery = React.lazy(()=>import('./Last/gallery'))
const Explore = React.lazy(()=>import('./Explore/explore'))
const Presets = React.lazy(()=>import('./Middle/Presets'))
const Feel = React.lazy(()=>import('./Explore/feel'))
const Reviews_Colabs = React.lazy(()=>import('./Last/reviews'))
const Process = React.lazy(()=>import('./Middle/process'))
const Foot = React.lazy(()=>import('../footer'))

export default function HomePage(){

    // const gallery = useMemo(
    //     () => [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10],
    //     []
    //   );
    const images = [ suit,
        shirt_pant,
        blazer,
        kurta,
        uniform,
        shirt,
        pant,
        sherwani,
        jubba,
        tshirt,
        jackets,
        longCoat  ]
    function scrollToElement(id) {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }
    

    return(
    <div className="HomePage">
        
        <React.Suspense>
        <Hero scrollToElement = {scrollToElement}/>
        <React.Suspense>
            <Explore {...{"images":images}}/>
        </React.Suspense>
        <React.Suspense>
            <Process/>
        </React.Suspense>
        <React.Suspense>
            <Presets/>
        </React.Suspense>
        {/* <React.Suspense>
            <Feel/>
        </React.Suspense> */}
        <React.Suspense>
            <Reviews_Colabs/>
        </React.Suspense>
        {/* <React.Suspense>
            <Gallery {...{"images":gallery}}/>
        </React.Suspense> */}
            <Foot/>
        
            </React.Suspense>       
    </div>
    )
}