import { Types } from "../Data";


import blazer from '../assets/icon/SVG2/coat.svg'
import coat from "../assets/SVG/double button-01.svg";

import trouser from '../assets/icon/SVG2/pant.svg'
import pajama from '../assets/SVG/SVG_PAJAMA/SALWAR-01.svg'
import vest from '../assets/icon/SVG2/vest.svg'
import sadri from '../assets/SVG/SVG_SADRI/STYLE 1-01.svg'

import Shirt from '../assets/icon/SVG2/shirt.svg'

import { useEffect, useState } from "react";


function CloneM({addToCart_s}) {

    
    const [category, setCategory] = useState()
    const [isMobile, setIsMobile] = useState(false)

    const [types, setTypes] = useState()
    
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 480); // Adjust the width as needed
        };

        // Initial check
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Clean up event listener on unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useState(()=>{
        if(!category){ 
            const data = JSON.parse(sessionStorage.getItem("sel"))
            if(data?.category){
                setCategory(data.category)
                setTypes(  Object.keys(data?.fabric) )
            }
        }
    }, [])  

    return ( 
        <div className="clone_m hori_center">
            <div className="title-font"> Clone measurements from your cloth </div>
            <div className="txt-font1 clone_m_des" > Do you have a {category ? category : ""} piece that we can clone for the measurement ? if yes, then you are are at the right place.
                Our Courier partner will pick the piece from your selected location and we will take care of the rest. 
                <a className="a_tag" href="/terms" style={{textDecoration:"none"}} > Terms and conditions </a>
            </div>
            
            <div className="clone_m_section">
                <div className="clone_m_left txt-font1">
                    <div><b>Instructions</b></div>
                    <ul>
                        <li> Add the Pickup location of your piece during the checkout </li>
                        <li> Delivery partner will pick up your {category ? category : ""} piece from the location </li>
                        <li> We will deliver your new {category ? category : ""} piece along with the one you provided </li>
                    </ul>
                    <div> <b>Note</b> : you need to provide all the mentioned pieces to the delivery partner at the time of the pickup </div>
                    {!isMobile && <button className="Butt1"
                        onClick={()=>{addToCart_s()}}
                    > <span> Proceed </span> </button>}
                </div>
                {(category && types) && 
                    <div className="clone_m_right">
                    <div className="txt-font">Pickup items for {category ? category : ""}</div>
                    <div className="clone_m_right_row">
                        { types.slice(0, 2).map((ele, key)=>{
                            return(
                                <div className="sel-card mob_sel_card"
                                    
                                    style={{"border":"1px solid black", "marginLeft":"0"}}
                                >
                                    <div className="sel-top">
                                        <label>{ele}</label>
                                    </div>
                                    
                                    <div style={{"width":"100%", "height":"7vw"}}>
                                        <img src={ category === "kurta" ? coat : Shirt } className="sel-card-img"
                                            style={ category === "kurta" ? {transform:"scale(1.4)"} : {}}
                                        />
                                    </div>
                                </div>
                            )
                        }) }
                    </div>
                    <div className="clone_m_right_row">
                        { types.slice(2, 4).map((ele, key)=>{
                            return(
                                <div className="sel-card mob_sel_card"
                                    
                                    style={ {"border":"1px solid black", "marginLeft":"0"}}
                                >
                                    <div className="sel-top">
                                        <label>{ele}</label>
                                    </div>
                                    
                                    <div style={{"width":"100%", "height":"7vw"}}>
                                        <img src={ category === "kurta" ? coat : Shirt } className="sel-card-img"
                                            style={ category === "kurta" ? {transform:"scale(1.4)"} : {}}
                                        />
                                    </div>
                                </div>
                            )
                        }) }
                    </div>
                </div>
                }
                
            </div>
        </div> 
    );
}

export default CloneM;