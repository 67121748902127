import close from '../../assets/icon/close.png'
import dArrow from '../../assets/icon/darrow_w.png'
import uArrow from '../../assets/icon/uparrow_w.png'

import lArrow from '../../assets/ALeft.png'
import rArrow from '../../assets/ARight.png'
import { useEffect, useMemo, useState } from 'react'

function Fabric_modal({hoverDetils,setHoverDets, setHover }) {

    const [hDes, setHdes] = useState(true)
    const [himg, setHimg] = useState(0)
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 480);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(()=>{
        console.log(hoverDetils)
    }, [hoverDetils])
    
    const fabricIcons = useMemo(()=>[
        {des:"Composition","attr":"material", "typ":0},
        {des:"Color", "attr":"color", "typ":1},
        {des:"Pattern", "attr":"pattern", "typ":0},
        {des:"Weave", "attr":"weave", "typ":1},
        {des:"Width", "attr":"width", "typ":1}
    ], [])

    return ( 
        <div className="s-modal"
            style={{"backgroundColor":"white"}}
        >
            {/* <div style={{"position":"relative", "width":"100%", "height":"100%", "backgroundColor":"white", "z-index":"1000"}}></div> */}
            <div
                onClick={()=>{
                    setHdes(true)
                    setHimg(0)
                    setHoverDets({})
                    setHover(-2)
                }}
            >
            
            <img 
                type="button"
            src={close} style={{right:"15px", "top":"15px", position:"absolute", "zIndex":"1", "width":"40px"}}/>
            
            </div>
        
            
            {himg === 0 ? 
                (<img 
                    // onLoad={imageLoaded}
                    src={hoverDetils['single'][0]} className="m-main-img"/>)
            : (
                <img 
                // onLoad={imageLoaded}
                src={himg === 1 ? hoverDetils['src'] : hoverDetils['single'][himg-1]} className="m-main-img"/>
            )
            }

            <img  src={lArrow} className="m-f-img-l"
                onClick={()=>{

                    if(himg>0){
                        // console.log("left click")
                        setHimg(himg-1)
                    }
                }}
            />
            <img src={rArrow}  className="m-f-img-r"
                onClick={()=>{
                    if(himg < hoverDetils['single'].length){
                        // console.log("Right click")
                        setHimg(himg+1)
                    }
                }}
            />
            
            <div className="s-hoverDes">
                <img src={hDes ? `${dArrow}` : `${uArrow}`} className="m-f-arrow hori_center"
                    onClick={()=>{setHdes(!hDes)}}
                />
                {(hDes) ? (<>
                <div className="s-h-container">
                <div className="s-h-des-1">
                    <label className="txt-font">{hoverDetils["name"]}</label>
                    <label className="txt-font1">by</label>
                    <img src={hoverDetils.brandImg} className="s-f-des-img hori_center" />
                    
                    <br/>

                    <label className="txt-small">{hoverDetils["des"]}</label>  
                    
                </div>
                <div className="s-h-line hori_center" ></div>
                <div
                    style={{"width":"10%", "height":"100%"}}
                ></div>
                <div className="s-h-icons">
                    {fabricIcons.map((val1, key1)=>{
                        return(<div style={ isMobile ? {"width":"90%","position":"relative"}:{"width":"90%", "position":"relative"}} className="hori_center">
                            <label>{val1["des"] != "Composition" ? val1["des"]: "" }</label>
                            <label style={{"position":"absolute", "right":"0"}}>
                            {hoverDetils[val1["attr"]]}
                            {/* gr/Meter */}
                            </label>
                        </div>)
                    })}
                </div>
                </div>
                </>): (
                <div>
                    
                    <b>{hoverDetils["name"]} , {hoverDetils["brand"]} </b>
                </div>) }
            </div>
        </div>
     );
}

export default Fabric_modal;