import { useEffect, useMemo, useRef, useState } from "react"
// import { useHistory } from 'react-router-dom'


import SelectFabric from "./Select/selectFabric"
import ViewFabric from "./View/viewFabric"
import "./fabric.css"
import youtube from '../assets/Arrow/yt.png'

import m_logo from '../assets/measur.svg'
import close from '../assets/icon/close_s.png'

import dArrow from '../assets/icon/darrow_w.png'
import uArrow from '../assets/icon/uparrow_w.png'

import barrowL from '../assets/Arrow/barrowL1.png'
import barrowR from '../assets/Arrow/barrowR.png'

import bclose from '../assets/Arrow/bclose.png'

import lArrow from '../assets/ALeft.png'
import rArrow from '../assets/ARight.png'

import logo_name from '../assets/logo_name.svg'

import raymond from '../assets/icon/Raymond.svg'

import aim from '../assets/samples/anima.gif'

// import {Fabrics_all} from '../Data/index'
import { fabricFilter, StylesFilter} from '../Data/index'
import StyleP from "./Style"
import Measurement from "../Measurements"
import NavBar from "../Navbar"
import { useMatch, useNavigate, useParams } from "react-router-dom"
import { getData, getDatafromDoc, getDocOnCondition, getFabricDetails } from "../Firebase/firestoreGet"

import { Types } from "../Data/index"
import FilterFabric from "./Select/Filter"
import { db } from "../firebase"
import { checkCategory } from "../Functions"

// import wrist from '../assets/Body/wrist.png'
// import hands from '../assets/Body/hand.png'

import wrist from '../assets/Body/formal_hand_2.webp'
import hands from '../assets/Body/formal_hand_1.webp'

// import kurta_hand_k from '../assets/Body/kurta_hand_k.png'

import pajama_body from '../assets/Body/pajama_body.png'

export default function Fabric(){

    const {id} = useParams()
    const {category} = useParams()
    const {process} = useParams()

    

    const navigate = useNavigate()
    // const history = useHistory()

    const [Fabrics_all, setFabricsAll] = useState({})
    const [useFabric, setUseFabric] = useState({})
    

    const [nav, setNav] = useState( id!=null ? Number(id) :
        checkCategory(category)?-1: 0);

    useEffect(()=>{
        if(id != null && process){
            setNav(Number(id))
        } else  {
            navigate( `/custom/${category}` )
            // setNav( ["suit", "kurta"].includes(category) ? -1 : 0 )
        }
    }, [id])

    const [loading, setLoading] = useState(true)

    const [clickHover, setClickHover] = useState(0)

    const [state, setStates] = useState(1)
    const [single, setSingle] = useState([])

    const [section, setSection] = useState(0)
    
    const [fabSame, setFabSame] = useState(process ? false :
        category === "suit" ? true : false
    )

    const [indication, setIndication] = useState(false)

    const [jodh, setJodh] = useState(0)

    const [LoadFabInfo, setFabricInfoLoad] = useState(true)

    const [STYLESALL, setStylesAll] = useState({
        "Coat":{
            display : {},
            style :{}   
        },
        "Vest":{
            display : {},
            style :{}   
        },
        "Shirt":{
            display : {},
            style :{}   
        },
        "Pant":{
            display : {},
            style :{}   
        },
        "Kurta":{
            display : {},
            style :{}   
        },
        "Pajama":{
            display : {},
            style :{}   
        },
        "Sadri":{
            display : {},
            style :{}   
        }
    })

    const fabricIcons = useMemo(()=>[
        {des:"Color", "attr":"color", "typ":1},
        {des:"Pattern", "attr":"pattern", "typ":0},
        {des:"Composition","attr":"material", "typ":0},
        {des:"Weave", "attr":"weave", "typ":1},
        {des:"Width", "attr":"width", "typ":1}
    ], [])
    const [sel, setSel] = useState(!process ? { 
        "fabric":{"name":"White Wool", "image":"", "price":""},
        "style":{},
        "price":"",
        "name":"Custom "+category,
        "type":1
    } : JSON.parse(sessionStorage.getItem("sel")) )

    const [FabricTypes, setFabricTypes] = useState()
    const [fabricType,setType ] = useState("")

    const [hoverDetils, setHoverDets] = useState({})
    const [sHover, setHover] = useState(-2)
    const [edit , setEdit] = useState(false)

    const [hDes, setHdes] = useState(true)
    const [himg, setHimg] = useState(0)

    const [menuOpen2, setMenuOpen2] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);

    const [imgState, setImgState] = useState(
        0
    )

    const [mess, setMess] = useState(true);

    /// main function to set rules and initiate vars
    useEffect(()=>{
        // suit , pant , shirt , formal , blazer
        console.log("Catr : ", category)
        if(process){
            const sel_obj = JSON.parse(sessionStorage.getItem("sel"))
            setFabricTypes(Object.keys(sel_obj.fabric))
            setSel(sel_obj)
            setNav(Number(id))
            setImgState(0)
            var fin = {}
            Object.keys(sel_obj.fabric).forEach((e)=>{
                fin[e] = {
                    display : {},
                    style :{}   
                }
            })
            setStylesAll(fin)
        } else {

        
        if(category === "suit"){

            
        } else if(category === "formal"){
            
            var fabric = {}
            
            fabric["Shirt"] = {
                "brand":"" ,// "" Fabric[Object.keys(Types)[i]][0]["brand"], 
                "name":"" ,//Fabric[Object.keys(Types)[i]][0]["fabric"], 
                "price":"" ,//Fabric[Object.keys(Types)[i]][0]["price"],
                "src":"" ,//Fabric[Object.keys(Types)[i]][0]["src"],
                "pos":Types[category]["Shirt"]}

            fabric["Pant"] = {
                    "brand":"" ,// "" Fabric[Object.keys(Types)[i]][0]["brand"], 
                    "name":"" ,//Fabric[Object.keys(Types)[i]][0]["fabric"], 
                    "price":"" ,//Fabric[Object.keys(Types)[i]][0]["price"],
                    "src":"" ,//Fabric[Object.keys(Types)[i]][0]["src"],
                    "pos":Types[category]["Pant"]}
            
            setFabricTypes(Object.keys(fabric))
            
            setSel({
                ...sel, 
                "types":Object.keys(fabric),
                "fabric":fabric,
                category:category
            })
            setNav(0)
            
            
            setImgState(0)
            setStylesAll(
                {
                    "Shirt":{
                        display : {},
                        style :{}   
                    },
                    "Pant":{
                        display : {},
                        style :{}   
                    }
                }
            )
        } else if(category === "shirt"){
            setNav(0)
            var fabric = {}

            fabric["Shirt"] = {
                "brand":"" ,// "" Fabric[Object.keys(Types)[i]][0]["brand"], 
                "name":"" ,//Fabric[Object.keys(Types)[i]][0]["fabric"], 
                "price":"" ,//Fabric[Object.keys(Types)[i]][0]["price"],
                "src":"" ,//Fabric[Object.keys(Types)[i]][0]["src"],
                "pos":Types[category]["Shirt"]}
            
                
            
            setFabricTypes(Object.keys(fabric))
            
            setSel({
                ...sel, 
                "types":Object.keys(fabric),
                "fabric":fabric,
                category:category
            })
            
            setImgState(0)
            setStylesAll(
                {
                    "Shirt":{
                        display : {},
                        style :{}   
                    }
                }
            )
            console.log("NAV :", nav)
        } else if(category === "pant"){
            setNav(0)
            var fabric = {}
            const types =  [0, 0, 1, 0]
            console.log("TYPES :", types)

            fabric["Pant"] = {
                "brand":"" ,// "" Fabric[Object.keys(Types)[i]][0]["brand"], 
                "name":"" ,//Fabric[Object.keys(Types)[i]][0]["fabric"], 
                "price":"" ,//Fabric[Object.keys(Types)[i]][0]["price"],
                "src":"" ,//Fabric[Object.keys(Types)[i]][0]["src"],
                "pos": 0 }
                
            
            setFabricTypes(Object.keys(fabric))
            
            setSel({
                ...sel, 
                "types":Object.keys(fabric),
                "fabric":fabric,
                category:category
            })
            
            setImgState(0)
            setStylesAll(
                {
                    "Pant":{
                        display : {},
                        style :{}   
                    }
                }
            )
        } else if(category === "blazer" || category === "sadri"){
            const val_sel = category === "blazer" ? "Coat" : "Sadri"
            setNav(0)
            var fabric = {}

            fabric[val_sel] = {
                "brand":"" ,// "" Fabric[Object.keys(Types)[i]][0]["brand"], 
                "name":"" ,//Fabric[Object.keys(Types)[i]][0]["fabric"], 
                "price":"" ,//Fabric[Object.keys(Types)[i]][0]["price"],
                "src":"" ,//Fabric[Object.keys(Types)[i]][0]["src"],
                "pos":Types[category][val_sel]}
            
                
            
            setFabricTypes(Object.keys(fabric))
            
            setSel({
                ...sel, 
                "types":Object.keys(fabric),
                "fabric":fabric,
                category:category
            })
            
            setImgState(0)
            setStylesAll(
                {
                    [val_sel]:{
                        display : {},
                        style :{}   
                    }
                }
            )
            console.log("Done with this")
        } else {
            
        }
        }

    }, [])


    const getFabricDetails1 = async(id, attr = "id")=>{

        // /Fabric/Suits/fabrics
        const coll = db.collection("Fabric").doc("Suits").collection("fabrics")
        let data = []
        if(id){
            data  = await getDocOnCondition(coll,attr, id )
            return data[0]
        } else {
            data  = await getData(coll)
            return data
        }
    }

    function backMove () {
        // console.log("Curr nav", nav)
        if(section === 0){
            // console.log("executing this")
            setNav((curr)=> curr-1)
        } else {
            // console.log("executing 0")
            setSection(0)
        }
        
        
    }
    const counter = useRef(0)

    const imageLoaded = (c = 1) => {
        counter.current += 1;
        let L = 1;
        
        if (counter.current >= L) {
            // console.log("Removing")
            setFabricInfoLoad(false);
            counter.current = 0;
        }
    }

    useEffect(()=>{
        window.history.pushState( 'dummy', document.title, window.location.href )

        window.addEventListener('popstate', backMove);
    }, [])

    /// for changing the fabric selection state on image change
    useEffect(()=>{
        if(FabricTypes){
            if(category === "suit"){
                if(imgState <=2){
                    setType("Coat")
                }else{
                    if(FabricTypes.includes("Shirt")){
                        if(imgState === 3){
                            setType("Shirt")
                        } else if(imgState <= 5){
                            setType("Pant")
                        } else{
                            setType("Vest")
                        }
    
                        
                    }else{
                        if(imgState <= 4){
                            setType("Pant")
                        } else{
                            setType("Vest")
                        }
                        
                    }
                }
            } else if(category === "formal"){
                if(imgState <=2 ){
                    setType("Shirt")
                } else {
                    setType("Pant")
                }
            }

            
            
        }
        
    }, [imgState])

    useEffect(()=>{
        const element = document.getElementById(`style_type_${fabricType}`);
        element?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
    }, [fabricType])

    useEffect(()=>{
        if(sel && sel.fabric && sel.fabric[fabricType]){
            const fab_to = `fabriclist_${sel.fabric[fabricType].brand}_${sel.fabric[fabricType].fabric}`.replace(" ", "")
            const element = document.getElementById(fab_to);
            element?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
        }
        
    }, [sel, fabricType])

    


    //// for fabric details on fabric info click
    useEffect(()=>{
        
        if( hoverDetils && Object.hasOwn(hoverDetils, "q")){
            
            getFabricDetails1(hoverDetils["q"]).then((data)=>{  
                // console.log("From inside promise :", data)
                var Data = {...data}
                var s = ""
                Data.color.forEach((ele)=>{
                    if(ele.length > 0){
                        s = s + ele
                    }
                })
                Data["color"] = s
                setHoverDets(Data)
            }).catch((e)=>{
                // console.log("from useEffect", e)
            })
        }
    }, [hoverDetils])


    const getStyleImg = async(inp = null, l = 0, types = null)=>{
        try{
            // setOn(0)
            console.log("start of get Styles")
            setLoading(true)
            var Obj = {}
            if(inp){
                Obj = {...inp}
            }else {
                Obj = {...sel}
            }
            console.log("Object received", Obj)
            // console.log("THIS IS BEING USED :", Obj)
            // var Obj = {...sel}
            let styles = Obj.style

            var styleTypes = []
            if(types){
                styleTypes = types
            } else {
                styleTypes = Object.keys(styles)            
            }
            
            
            // let Obj = {...sel}
            var StylesALL = {
                "Coat":{
                    display : {},
                    style :{}
                },
                "Vest":{
                    display : {},
                    style :{}
                },
                "Shirt":{
                    display : {},
                    style :{}
                },
                "Pant":{
                    display : {},
                    style :{}
                },
                "Kurta":{
                    display : {},
                    style :{}
                },
                "Pajama":{
                    display : {},
                    style :{}
                },
                "Sadri":{
                    display : {},
                    style :{}
                }
            }
            if(!inp){
                console.log("setting styles all from this shit, ", STYLESALL)
                StylesALL = {...STYLESALL}
            }
            console.log("before , ", StylesALL)
            // console.log("Styles: ", StylesALL)
            for(let j = 0;j<styleTypes.length;j++){
                Obj["fabric"][styleTypes[j]]["single"] = []

                const coll  = category === "suit" ? "Suits" 
                    : category === "formal" || category === "pant" || category === "shirt" ? "formal"
                    : category === "kurta" ? "kurta" 
                    : category === "blazer" ? "blazer"
                    : category === "sadri" ? "kurta"
                    : "dummy" ;

                const refString = `Fabric/${coll}/${styleTypes[j]}_style/${Obj["fabric"][styleTypes[j]]["id"]}`
                const ref = db.doc(refString)

                // Obj["fabric"][styleTypes[j]]["style"]
                // console.log(styleTypes[j], "this is what its taking from ", Obj["fabric"][styleTypes[j]])
                // console.log("ref", ref)
                
                let Doc = styles[styleTypes[j]]
                for(let k = 0;k<Object.keys(Doc).length;k++){
                    const element = Object.keys(Doc)[k]

                    

                    var name = ""
                    
                    Doc[element].forEach((id)=>{
                        if(id.length){
                            name+=id+"-"
                        }
                        
                    })
                    if(Doc[element].length === 1){
                        name = Doc[element][0]
                    }
                    if (name[name.length - 1] === "-") {
                        name = name.slice(0, name.length - 1);
                        console.log("comging here to fix", name.slice(0, name.length - 1))
                    }

                    console.log(name)
                    //// Rules
                    if(styleTypes[j] === "Pant"){

                        if(name.includes("-C-H")){ name = name.slice(0, name.length-4) }
                    }

                    if(styleTypes[j] === "Pajama"){
                        if(name.includes("PP")){ name = "PP" }
                    }

                    if(styleTypes[j] === "Coat" && name[0] === "J"){
                        if(name.includes("-Y")){
                            Obj.style.Coat.F[3] = ""
                        }
                        name = name.split("-Y")[0]

                        name = name.slice(0, 2)+ "-"+name.slice(name.length-2)
                        // console.log("Jodhpuri :", name)
                        Obj["style"]["Coat"]["F"][1] = "0"
                        if(name.split("-")[0] === "JA"){
                            name =  "JH-AA"
                            console.log("chaning to ", name)
                        }
                    }
                    
                    if(styleTypes[j] === "Coat" && name.length > 5 && Obj["style"]["Coat"]["F"][1] === "0"){
                        // console.log("Changing the default lapel")
                        Obj["style"]["Coat"]["F"][1] = "N"
                        var t = ""
                        for(let i=0;i<name.length;i++){
                            if(name[i] === "0"){
                                t = t + "N"
                                
                            }else{
                                t = t+ name[i]
                            }
                        }
                        name = t
                    }
                    // console.log("Name ", name)
                    if (styleTypes[j] === "Coat" && (name.length > 2 || name === "JA")) {
                        if (StylesALL[styleTypes[j]]["style"]["d-" + name]) {
                            Obj["fabric"]["Coat"]["src"] = StylesALL[styleTypes[j]]["style"]["d-" + name];
                        } else {
                            const src = (await getDatafromDoc(ref.collection("display").doc(name)))["src"];
                            StylesALL[styleTypes[j]]["style"]["d-" + name] = src;
                            if (src && src.length > 0) {
                                Obj["fabric"]["Coat"]["src"] = src;
                            }
                        }
                    }
                    
                    if(styleTypes[j] === "Shirt"  ){

                        // [ "BASE", "P1", "SS", "F", "N"]

                        // const temp_name = name;

                        const L = name.split("-")
                        let tot = L.length
                        if(L.includes("H")){
                            // this is the place
                            L[L.length-1] = "0"
                        } else {
                            L[L.length-2] = "SLEVE"
                        }
                        let check = -1
                        if(L[L.length-1] === "C"){
                            check = L.length-2
                        }
                        let shirtImgs = []
                        for(let m = 0;m<tot;m++){
                            if(L[m] !="0" && (m!=check) ){
                                // console.log("Shirt running for : ",L[m])
                                if(StylesALL[styleTypes[j]]["style"] && !Object.hasOwn(StylesALL[styleTypes[j]]["style"], L[m])){
                                    // console.log("Shirt : ", ref, L[m], Obj["fabric"][styleTypes[j]])
                                    const src= (await getDatafromDoc(ref.collection("style").doc(L[m])))["src"]
                                    if(src && src.length > 0){
                                        StylesALL[styleTypes[j]]["style"][L[m]] = src 
                                    }                                    
                                } 

                                shirtImgs.push(StylesALL[styleTypes[j]]["style"][L[m]])
                            }
                        }
                        Obj["fabric"][styleTypes[j]]["single"].push({
                            "typ": 1,
                            "src": shirtImgs
                        });
                    }

                    if(styleTypes[j] === "Kurta"){
                        

                        // [ "R", "NS3", "00","R","0", "R", "SE" ,"CM","SN", "US1" ]
                        
                        console.log("Kurta Start")

                        var L = name.split("-")
                        let tot = L.length
                        
                        if(L[2] === "00"){  
                            L[3] = "0"
                            
                            L[4] = "0"
                            L[5] = "0"
                        }
                        if(L[2] === "01"){  
                            L[3] = "R"+L[3] 
                            if(L[4] === "1"){
                                L[4] = "0"
                                L[5] = "FR"+L[5]
                                L.push( "BPR" )
                            } else {
                                L[4] = "0"
                                L[5] = "0"
                            }
                            
                            
                        }
                        if(L[2] === "11"){  
                            L[2] = "L"+L[3]; 
                            L[3] = "R"+L[3] ; 
                            

                            if(L[4] === "1"){
                                L[4] = "FL"+L[5]
                                L[5] = "FR"+L[5]
                                L.push( "BPR" )
                                
                                L.push( "BPL" )
                            } else {
                                L[4] = "0"
                                L[5] = "0"
                            }
                            
                        }
                        if(L[6] === "SE"){
                            L.push( "BE" )
                        }

                        if( ["CR", "CB", "CT", "CS", "CE"].includes(L[7])){
                            var tmmp = L[7]
                            L[7] = "CA"
                            L.push(tmmp)
                            L[1] = L[1]+"3"
                            if(L[0] === "R"){
                                L[0] = "D"
                            } else {
                                L[0] = "T"
                            }
                            
                            /// Shirt Collar
                            // if(["NS4", "QS4"].includes(L[1])){
                            //     L[1] = L[1][0]+L[1][1]+"3"
                            //     Obj.style.Kurta.F[1] = L[1]
                            // }
                            L.push( "BKN" ) /// for buton show
                        } else {
                            
                            /// chinese collar
                            L[1] = L[1]+"4"
                            if(L[7] === "CA"){
                                var tpp = L[1]
                                L[1] = "CN"
                                L[7] = tpp;


                            }
                            // if(["NS3", "QS3"].includes(L[1])){
                            //     L[1] = L[1][0]+L[1][1]+"4"
                            //     Obj.style.Kurta.F[1] = L[1]
                            // }
                            L.push( "BKC" )
                        }
                        if( L[8] == "SN" ){
                            L[9] = "0"
                            L.push("U00")
                        } else {
                            if(L[9][2] === "1"){
                                L.push("BC2")
                            } else {
                                L.push("BC4")
                            }
                        }

                        if(L.includes("SN")){
                            L = ["BASE", ...L]
                        }else{
                            L = ["BASE" , "SLEVE" , ...L]
                        }

                        
                        console.log("final names for Kurta ", L)
                        tot = L.length
                        // console.log("Kurta final names", L)
                        
                        let check = -1
                        
                        let shirtImgs = []

                        
                        for(let m = 0;m<tot;m++){
                            if( !/^0+$/.test(L[m]) && (m!=check) && L[m] != "BPL" ){
                                // console.log("Kurta Style running for : ", L[m], StylesALL[styleTypes[j]]["style"] , !Object.hasOwn(StylesALL[styleTypes[j]]["style"], L[m]))
                                if(StylesALL[styleTypes[j]]["style"] && !Object.hasOwn(StylesALL[styleTypes[j]]["style"], L[m])){
                                    
                                    
                                    const src= (await getDatafromDoc(ref.collection("style").doc(L[m])))
                                    if(!src || !src.src || ( src.src && src.src.length === 0 )){
                                        // console.log("Style not available", L[m])
                                    } else {
                                        
                                        StylesALL[styleTypes[j]]["style"][L[m]] = src["src"]
                                        shirtImgs.push(StylesALL[styleTypes[j]]["style"][L[m]])
                                    }
                                } else {
                                    
                                    shirtImgs.push(StylesALL[styleTypes[j]]["style"][L[m]])
                                } 
                            }
                        }

                        
                        Obj["fabric"][styleTypes[j]]["single"].push({
                            "typ": 1,
                            "src": shirtImgs
                        });

                        const display_shirt = []
                        //// display images for kurta
                        
                        for(let m = 0;m<tot;m++){
                            
                            if( !/^0+$/.test(L[m]) && L[m] != "CA" ){
                                if(StylesALL[styleTypes[j]]["display"] && !Object.hasOwn(StylesALL[styleTypes[j]]["display"], L[m])){
                                    // console.log("Shirt : ", ref, L[m], Obj["fabric"][styleTypes[j]])
                                    
                                    const src= (await getDatafromDoc(ref.collection("display").doc(L[m])))
                                    if(!src || !src.src || ( src.src && src.src.length === 0 )){
                                        // console.log("display not available", L[m])
                                    } else {
                                        StylesALL[styleTypes[j]]["display"][L[m]] = src["src"]
                                        display_shirt.push( StylesALL[styleTypes[j]]["display"][L[m]] )
                                    }                                    
                                } else {
                                    display_shirt.push( StylesALL[styleTypes[j]]["display"][L[m]] )
                                }

                                
                            }
                            // if(["R", "S", "D", "T"].includes(L[m])){
                            //     display_shirt.push( kurta_hand_k)   
                            // }
                        }
                        Obj["fabric"][styleTypes[j]]["src"] = display_shirt


                        /// default kurta when coat and sadri

                        if(Obj.types.includes("Coat") || Obj.types.includes("Sadri")){

                            const defaults = []

                            const extra = Obj.types.includes("Coat") ? "COAT" : "SADRI"

                            var def_name = Obj.style.Kurta.F[0]+"-"+extra

                            console.log("fetching default for Kurta",def_name)

                            if(StylesALL[styleTypes[j]]["display"] && !Object.hasOwn(StylesALL[styleTypes[j]]["display"], def_name)){
                                
                                
                                const src= (await getDatafromDoc(ref.collection("display").doc( def_name )))

                                if(!src || !src.src || ( src.src && src.src.length === 0 )){
                                    console.log("display not available", def_name)
                                } else {
                                    StylesALL[styleTypes[j]]["display"][def_name] = src["src"]
                                    defaults.push( src.src )
                                }                                    
                            } else {
                                defaults.push( StylesALL[styleTypes[j]]["display"][def_name] )
                            }
                            if(extra === "SADRI"){
                                
                                def_name = Obj.style.Kurta.F[8]+"-"+extra

                                if(StylesALL[styleTypes[j]]["display"] && !Object.hasOwn(StylesALL[styleTypes[j]]["display"], def_name)){
                                
                                
                                    const src= (await getDatafromDoc(ref.collection("display").doc( def_name )))
    
                                    if(!src || !src.src || ( src.src && src.src.length === 0 )){
                                        console.log("display not available", def_name)
                                    } else {
                                        StylesALL[styleTypes[j]]["display"][def_name] = src["src"]
                                        defaults.push( src.src )
                                    }                                    
                                } else {
                                    defaults.push( StylesALL[styleTypes[j]]["display"][def_name] )
                                }
                            }

                            Obj["fabric"][styleTypes[j]]["default"] =  defaults
                        }   


                        console.log("Kurta End")
                    }

                    if(styleTypes[j] === "Sadri"){
                        var L = name.split("-")
                        let tot = L.length

                        L.push("B"+L[0])
                        if(L[1] === "1"){
                            L[1] = "0"
                            L.push("V"+L[0])
                        }

                        tot = L.length
                        console.log("Sadri final names", L)
                        
                        let check = -1
                        
                        let shirtImgs = []

                        
                        for(let m = 0;m<tot;m++){
                            if( !/^0+$/.test(L[m]) && (m!=check) ){
                                // console.log("Kurta Style running for : ", L[m], StylesALL[styleTypes[j]]["style"] , !Object.hasOwn(StylesALL[styleTypes[j]]["style"], L[m]))
                                if(StylesALL[styleTypes[j]]["style"] && !Object.hasOwn(StylesALL[styleTypes[j]]["style"], L[m])){
                                    
                                    
                                    const src= (await getDatafromDoc(ref.collection("style").doc(L[m])))
                                    if(!src || !src.src || ( src.src && src.src.length === 0 )){
                                        console.log("Style not available", L[m])
                                    } else {
                                        
                                        StylesALL[styleTypes[j]]["style"][L[m]] = src["src"]
                                        shirtImgs.push(StylesALL[styleTypes[j]]["style"][L[m]])
                                    }
                                } else {
                                    
                                    shirtImgs.push(StylesALL[styleTypes[j]]["style"][L[m]])
                                } 
                            }
                        }

                        
                        Obj["fabric"][styleTypes[j]]["single"].push({
                            "typ": 1,
                            "src": shirtImgs
                        });

                        const display_shirt = []
                        //// display images for kurta
                        
                        for(let m = 0;m<tot;m++){
                            
                            if( !/^0+$/.test(L[m])){
                                if(StylesALL[styleTypes[j]]["display"] && !Object.hasOwn(StylesALL[styleTypes[j]]["display"], L[m])){
                                    // console.log("Shirt : ", ref, L[m], Obj["fabric"][styleTypes[j]])
                                    
                                    const src= (await getDatafromDoc(ref.collection("display").doc(L[m])))
                                    if(!src || !src.src || ( src.src && src.src.length === 0 )){
                                        console.log("display not available", L[m])
                                    } else {
                                        StylesALL[styleTypes[j]]["display"][L[m]] = src["src"]
                                        display_shirt.push( StylesALL[styleTypes[j]]["display"][L[m]] )
                                    }                                    
                                } else {
                                    display_shirt.push( StylesALL[styleTypes[j]]["display"][L[m]] )
                                }

                                
                            }
                            if(m === 3){
                                // display_shirt.push( L[m] === "F" ? wrist : hands  )
                            }
                        }
                        Obj["fabric"][styleTypes[j]]["src"] = display_shirt

                        console.log("Sadri complete")
                    }
                    

                    /// for setting display images for shirt
                    if( ( category === "shirt" || ( category === "formal" && styleTypes[j] === "Shirt" ) ) && element === "F" ){

                        const display_shirt = []
                        const L = name.split("-")
                        console.log("Display images for shirt")
                        console.log(L)

                        // change base if mandrian is selected
                        if(L[2] === "0"){
                            L[0] = "M0"
                        }
                        let tot = L.length
                        // [ "BASE", "P1", "SS", "F", "N"]\
                        for(let m = 0;m<tot-1;m++){
                            
                            if(L[m] !="0"){
                                if(StylesALL[styleTypes[j]]["display"] && !Object.hasOwn(StylesALL[styleTypes[j]]["display"], L[m])){
                                    // console.log("Shirt : ", ref, L[m], Obj["fabric"][styleTypes[j]])
                                    const src= (await getDatafromDoc(ref.collection("display").doc(L[m])))["src"]
                                    if(src && src.length > 0){
                                        StylesALL[styleTypes[j]]["display"][L[m]] = src 
                                    }                                    
                                }

                                display_shirt.push( StylesALL[styleTypes[j]]["display"][L[m]] )
                            }
                            if(m === 3){
                                display_shirt.push( L[m] === "F" ? wrist : hands  )
                            }
                        }
                        Obj["fabric"][styleTypes[j]]["src"] = display_shirt

                    }

                    if (StylesALL[styleTypes[j]]["style"] && !Object.hasOwn(StylesALL[styleTypes[j]]["style"], name) && styleTypes[j] !== "Shirt" && styleTypes[j] !== "Kurta" && styleTypes[j] !== "Sadri") {
                        if (name && name.length > 0) {
                            const src = (await getDatafromDoc(ref.collection("style").doc(name)))["src"];
                            
                            // console.log("Pajama Style img", name, src)
                            if (src && src.length > 0) {
                                StylesALL[styleTypes[j]]["style"][name] = src;
                            }
                        }
                    }
                    
                    // / for changing the display image based on style
                    if((category === "pant" || (category === "formal" && styleTypes[j] === "Pant")) && name[0] === "F"){

                        console.log("coming in here for suit also")
                        if(StylesALL[styleTypes[j]]["display"] && !Object.hasOwn(StylesALL[styleTypes[j]]["display"], name)){   
                            if (name && name.length > 0) {
                                const src = (await getDatafromDoc(ref.collection("display").doc(name)))["src"];
                                // console.log("from display for ", name, src)
                                if (src && src.length > 0) {
                                    StylesALL[styleTypes[j]]["display"][name] = src;
                                }
                            }
                        }

                        
                        Obj["fabric"][styleTypes[j]]["src"] = StylesALL[styleTypes[j]]["display"][name]
                        
                    }

                    

                    if (styleTypes[j] !== "Shirt" && styleTypes[j] !== "Kurta" && styleTypes[j] !== "Sadri") {

                        if(styleTypes[j] === "Pajama"){
                            Obj["fabric"][styleTypes[j]]["single"].push({
                                "typ": 1,
                                "src": [ pajama_body , StylesALL[styleTypes[j]]["style"][name]]
                            });
                        } else {
                            Obj["fabric"][styleTypes[j]]["single"].push({
                                "typ": 0,
                                "src": StylesALL[styleTypes[j]]["style"][name]
                            });
                        }
                        
                    }

                    if(styleTypes[j] === "Pajama"){
                        name = name.split("-")[0]
                        if(StylesALL[styleTypes[j]]["display"] && !Object.hasOwn(StylesALL[styleTypes[j]]["display"], name)){   
                            if (name && name.length > 0) {
                                const src = (await getDatafromDoc(ref.collection("display").doc(name)))["src"];
                                // console.log("from display for ", name, src)
                                if (src && src.length > 0) {
                                    StylesALL[styleTypes[j]]["display"][name] = src;
                                }
                            }
                        }

                        
                        Obj["fabric"][styleTypes[j]]["src"] = StylesALL[styleTypes[j]]["display"][name]
                    }
                }
            }

            console.log("its coming here")
            setStylesAll(StylesALL);
            setSel(Obj);
            // console.log("--------------Final sel Object", Obj)
            // setOn(1);
            // sleep(1500)
            if(l === 1 ){
                setLoading(false);
            }
            
            return 1;
        } catch (e) {
            // setOn(1);
            setLoading(false);
            // console.log("from style fetching", e);
            return 0;
        }
    };

    function sortByKey(array, key) {
        return array.sort(function(a, b) {
            var x = a[key]; var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }
    
    useEffect(()=>{
        console.log("SEL is getting changed somewhere...", sel)
    }, [sel])


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 480);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(()=>{
        console.log("Changin nav :", nav)
        if(nav<-1){
            navigate("/")
        }
    }, [nav])
    
    // const computeDistount=  (obj)=>{
    //     for
    // }
    useEffect(()=>{
        let i = 0;
        let Single = []
        if(sel && sel.types){

            sel.types.forEach(element => {
                if(sel.fabric[element] && Object.hasOwn(sel.fabric[element], "single")){
                    i += sel.fabric[element]["single"].length
                    sel.fabric[element]["single"].forEach(val =>{
                        if(val["src"]){
                            Single.push(val)
                        }else{
                            Single.push({"src":val, "typ":0})
                        }
                        
                    })
                }
            });

            // console.log(Single, i)
            setStates(i)
            setSingle(Single)
        }
        
     }, [sel])

    useEffect(()=>{
        if(FabricTypes){
            setLoading(true)
            var defaultStyle = {
                "Coat":{
                    "F":["2B", "N", "FL", ""],
                    "B":["NV"]
                },
                "Pant":{
                    "F":["F-NP", "C", "H"],
                    "B":["B-SP"]
                },
                "Vest":{
                    "F":["SB"]
                },
                "Shirt":{
                    "F":["BASE", "P1", "SS", "SLEVE", "N"]
                },
                "Kurta":{
                    "F" : [ "S", "NS", "00","R","0", "R", "0" ,"CM","SN", "US1" ]
                },
                "Pajama":{
                    "F" : [ "PC", "E" ]
                },
                "Pajama":{
                    "F" : [ "PJ", "E" ]
                },
                "Sadri":{
                    "F" :["EE", "1"]
                }
            }

            if(category === "formal" || category === "shirt"){
                defaultStyle["Shirt"]["B"] = ["A"]
                defaultStyle["Shirt"]["F"] = [ "BASE", "P1", "SS", "F", "N"]
            }

            console.log("Types selecetd", FabricTypes)

            const sel_obj = JSON.parse(sessionStorage.getItem("sel"))

            getClothAndFabrics(sel_obj, defaultStyle)
            
        } else{
            console.log("calling none")
        }
    }, [FabricTypes])
    
    const getClothAndFabrics = async(sel_obj, defaultStyle)=>{
        let data = await getFabricDetails(FabricTypes, category)
        let fabrics_list =await getFabricDetails1(null)
            var same_fabric = {...data}
            var K = Object.keys(same_fabric)

            
            

            console.log(data)

            let add = {}

            if(category === "suit" && !process){
                for(let i=0;i< K.length ;i++){
                    if(K[i] !== "Shirt"){
                    same_fabric[K[i]] = sortByKey(same_fabric[K[i]], "fabricID")}
                }

                console.log("this is the prob")
                const defFabric = data["Coat"][0]["fabricID"]
                console.log("Sure", defFabric)
                Object.keys(data).forEach((ele)=>{
                    
                    const selectEle = data[ele].find((fabric) => fabric.fabricID === defFabric);
                    
                    if(selectEle){
                        // console.log("found same", selectEle["fabricImg"])
                    }
                    add[ele] = selectEle ? selectEle : data[ele][0]
                    add[ele]["pos"] = Types[category][ele]
                    
                })

                console.log("final done")
            } else {
                Object.keys(data).forEach((ele)=>{
                    
                    if(process && sel_obj){
                        add[ele] = sel_obj.fabric[ele]
                    } else{
                        add[ele] = data[ele][0]
                    }
                    add[ele]["pos"] = Types[category][ele]
                    
                })
            }
            
            
            setFabricsAll(same_fabric)
            setUseFabric(same_fabric)

            

            // console.log("Fabrics", same_fabric)
            
            let defStyle = {}
            
            if(!process){
                FabricTypes.forEach((typ)=>{
                    defStyle[typ] = defaultStyle[typ]
                })
            } else {
                defStyle = sel_obj.style
            }

            console.log("another one")
            if(!edit){

                let p = 0
                let D = Number(0)
                Object.keys(add).forEach((element)=>{
                    if(add[element]["discount"]){
                        const perc = Number(add[element]["discount"])
                        D = D + (Number(add[element]["price"])*( perc/100 ) )
                    }
                    p+=Number(add[element]["price"])
                }) 

                console.log("another 2")
                D = process ? sel_obj.discount : D
                p = process ? sel_obj.price : p

                // let price = 0
                // Object.keys(add).forEach((ele)=>{
                //     price+=Number(add[ele]["price"])
                // })
                // // console.log("Initial", add)
                
                // setSel(
                //     {...sel, ...{"fabric":add, "price":p,"discount":D, "style":{...defStyle}}}
                // )
                getStyleImg({...sel, ...{"fabric":add, "price":p,"discount":D, "style":{...defStyle}}}).then(()=>{

                })
                // console.log("from Refreshing", {...sel, ...{"fabric":add, "price":p,"discount":D, "style":{...defStyle}}}, defStyle, defaultStyle)
            }
            
            console.log("printing same fab",same_fabric)
            FabricTypes.forEach((e)=>{

                for(let i=0;i<same_fabric[e].length;i++){

                    const ele = same_fabric[e][i]
                    const fabI = ele.fabricID
                    const selfab = fabrics_list.find((fabric) => fabric.id === fabI);
                    if(selfab && selfab.id){
                        same_fabric[e][i]["brand"] = selfab.brand
                        same_fabric[e][i]["fabric"] = selfab.name
                        same_fabric[e][i]["fabricImg"] = selfab.src
                    }
                }
                
            })
            console.log("From main fabric", same_fabric)
        
    }

    const proceedToViewFabric = () => {
        setNav(1); 
    };
    const proceedToViewFabric2 = () => {
        setNav(-1); 
    };

    // useState(()=>{
    //     const session = JSON.parse(sessionStorage.getItem('sel'))
    //     if(session){
    //         setNav(0)
    //         setFabricTypes(session.types)
    //         setEdit(true)
    //         setSel(session)
    //     }
    // }, [])

    
    

    return(
        <div className="Fabric"
            style={ (nav<=1 )? {"overflow":"hidden"} : {  }}
        >

            
            
            { nav<=1 && nav>-1 && <div className="s-navigation"
                style={nav>1 ? {"top":"90px"}:{}}
            >
                
                <div className={`s-nav_list small-font ${isMobile ? 'mobile-nav-fab' : ''}`}>
                  
            
    
        <>
            <label className={`${nav >= 0 ? "s-nav-shade" : "s-nav"}`}
                onClick={(handleNavClick) => { setLoading(true); 
                    // console.log("setting"); 

                        if(process){    
                                navigate(`/custom/${category}/${0}/${process}`)
                            } else {
                                setNav(0)
                            }
                         }}
                type = "button"
            >
                Fabric
            </label>
            <div className="nav-line"></div>
            <label className={`${nav === 1 ? "s-nav-shade" : "s-nav"}`}
                onClick={() => { 
                    if(process){    
                        navigate(`/custom/${category}/${1}/${process}`)
                    } else {
                        setNav(1)
                    }
                    // console.log("changing"); 
                }}
                type = "button"
            >
                Style
            </label>
            <div className="nav-line"></div>
            <label className={`${nav === 2 ? "s-nav-shade" : "s-nav"}`}
                onClick={() => { 
                    sessionStorage.setItem("sel", JSON.stringify(sel))
                    if(process){    
                        navigate(`/measurements`)
                    } else {
                        setNav(2)
                    }
                    
                    // console.log("changing"); 
                }}
                type = "button"
            >
                Measure
            </label>
            {/* <label className={`${nav === 2 ? "s-nav-shade" : ""}`}
                onClick={() => { setNav(2); }}
            >
                <img src={m_logo} className={isMobile ? 'mobile-image2' : ''} />
            </label> */}
        </>
    
</div>

            
                </div>}

                {/* //// Logo */}
                {nav<=1 && 
                
                <a href="/" style={{textDecoration:"none"}}>
                <div className="s-logo verti_center txt-font"
                    style={{"zIndex":"101"}}
                    onClick={()=>{
                    }}
                    type="button"
                >
                    <img src={logo_name} style={{"width":"150px"}} />
                    
                </div>
                </a>
                }

                {/* //// Indication */}

                { (nav === 1 && indication && sel && sel.types && sel.types.includes("Shirt") ) && <>
                    <div className="f-back-dark"
                        onClick={()=>{
                            if(isMobile){
                                setMenuOpen(0)
                                setMenuOpen2(0)
                            }
                        }}
                    >
                    </div>
                    <div className="indication both_center"
                        onClick={()=>{
                            if(isMobile){
                                setMenuOpen(0)
                                setMenuOpen2(0)
                            }
                        }}
                    >
                        <div style={{"width":"100%", "position":"relative", "height":"100%"}}>
                            Hello Again !! <br/>
                            You are purchasing Shirt and selecting  <b>JodhPuri</b> as your Coat type. 
                            Shirts are not visible with Jodjpuri. You can continue to purchase if you wish to. 
                            <div style={{"display":"flex", "gap":"10px", "fontSize":"0.8rem", "marginTop":"30px"}}>
                            <button className="Butt1" 
                                onClick={()=>{setIndication(false)}}
                            > <span>
                                Continue</span> </button>
                            <button className="Butt1 orange_button" 
                                onClick={()=>{ setNav(-1) }}
                            > <span>Change Selection</span> </button>
                            </div>
                        </div>
                    </div>
                </>
                }

                
                

            
            {
            
            nav === -1 ? (<div className="SelectionBody">
            
              {isMobile ? (
                        <div className="select-fabric-container mobile-view">
                            
                            <SelectFabric
                                category={category}
                                Fabric={Fabrics_all}
                                useFabric = {useFabric}
                                setUseFabric = {setUseFabric}
                                sel={sel}
                                setSel={setSel}
                                sHover={setHoverDets}
                                hoverState={setHover}
                                hover={sHover}
                                nav={nav}
                                setNav={setNav}
                                setFabricsAll={setFabricsAll}
                                setFabricTypes={setFabricTypes}
                                FabricTypes={FabricTypes}

                                menuOpen2={menuOpen2}
                                setMenuOpen2={setMenuOpen2}
                                menuOpen = {menuOpen}
                                clickHover={clickHover}
                                setClickHover={setClickHover}

                                getStyleImg = {getStyleImg}
                                setStylesAll = {setStylesAll}

                                imgState = {imgState}
                                setImgState = {setImgState}

                                fabSame={fabSame}
                                setFabSame={setFabSame}
                                fabricType = {fabricType}
                                setType = {setType}

                                process={process}
                            />
                            
                        </div>
                        
                    ) : (
                        <SelectFabric
                            category={category}
                            Fabric={Fabrics_all}
                            useFabric = {useFabric}
                            setUseFabric = {setUseFabric}
                            sel={sel}
                            setSel={setSel}
                            sHover={setHoverDets}
                            hoverState={setHover}
                            hover={sHover}

                
                            nav={nav}
                            setNav={setNav}
                            setFabricsAll={setFabricsAll}
                            setFabricTypes={setFabricTypes}
                            FabricTypes={FabricTypes}

                            menuOpen2={menuOpen2}
                            setMenuOpen2={setMenuOpen2}
                            menuOpen = {menuOpen}

                            clickHover={clickHover}
                            setClickHover={setClickHover}

                            getStyleImg = {getStyleImg}
                            setStylesAll = {setStylesAll}

                            imgState = {imgState}
                            setImgState = {setImgState}

                            fabSame={fabSame}
                            setFabSame={setFabSame}

                            fabricType = {fabricType}
                            setType = {setType}

                            process={process}
                        />
                    )}
                  
                    
            </div>)  

            :nav == 0 ?  
            
                (<div className="fabricbody">
                    
                    
                    {/* Fabric page Filter */}
                    {true && (
                        <FilterFabric/>
                    )}

                    {/* Fabric hover details */}
                    {sHover == -1 && (
                        <div className="f-back-dark">
                        </div>
                    )
                    }
                    { (sHover == -1) && hoverDetils && (<div className="s-modal"
                        style={{"backgroundColor":"white"}}
                    >
                        {/* <div style={{"position":"relative", "width":"100%", "height":"100%", "backgroundColor":"white", "z-index":"1000"}}></div> */}
                        
                        {hoverDetils.link && hoverDetils.link.length > 0 && 
                           <a href={hoverDetils.link}>
                           <img style={{"position":"absolute", "left":"5px", "top":"5px", "zIndex":"1000", "width":"50px"}} src={youtube}/>
                           </a>
                        }
                        
                        <div style={{"position":"absolute", "backgroundColor":"white", "width":"25px", "left":"10px", "zIndex":"999"}} >  </div>
                        <div
                            onClick={()=>{
                                setHdes(true)
                                setHimg(0)
                                setHoverDets({})
                                setHover(-2)
                            }}
                        >
                        
                        <img 
                            type="button"
                        src={bclose} style={{right:"15px", "top":"15px", position:"absolute", "zIndex":"1", "width":"40px"}}/>
                        
                        </div>
                        
                        {/* {LoadFabInfo && (<div className='f-loading'
                            style={{"position":"absolute"}}
                        >
                            <img alt src={aim} onerror="this.src='https://d1fufvy4xao6k9.cloudfront.net/images/garment/loading.gif'"/>
                        </div>)} */}
                        { hoverDetils['single'] && hoverDetils['single'].length>0 ? 
                            (<>
                                {himg === 0 ? 
                                    (<img 
                                        // onLoad={imageLoaded}
                                        src={hoverDetils['single'][0]} className="m-main-img"/>)
                                : himg === 1 ? 
                                (
                                    <img 
                                    // onLoad={imageLoaded}
                                    src={hoverDetils['src']} className="m-main-img"/>
                                ) : 
                                (<img 
                                    // onLoad={imageLoaded}
                                    src={hoverDetils['single'][himg-1]} className="m-main-img"/>)
                                }
                            </>) 
                            : 
                            (
                                <img 
                                onLoad={imageLoaded}
                                src={
                                    hoverDetils['src']}
                                className="m-main-img"/>
                            )
                        }

                        

                        <img  src={barrowL} className="m-f-img-l"
                            type="button"
                            onClick={()=>{

                                if(himg>0){
                                    // console.log("left click")
                                    setHimg(himg-1)
                                }
                            }}
                        />
                        <img src={barrowR}  className="m-f-img-r"
                            type="button"
                            onClick={()=>{
                                if(himg < hoverDetils['single'].length){
                                    // console.log("Right click")
                                    setHimg(himg+1)
                                }
                            }}
                        />
                        
                        <div className="s-hoverDes">
                            <img src={hDes ? `${dArrow}` : `${uArrow}`} className="m-f-arrow hori_center"
                                type="button"
                                onClick={()=>{setHdes(!hDes)}}
                            />
                            {(hDes) ? (<>
                            <div className="s-h-container">
                            <div className="s-h-des-1">
                                <label className="txt-font">{hoverDetils["name"]}</label>
                                <label className="txt-font1">by</label>
                                <img src={hoverDetils.brandImg} className="s-f-des-img hori_center" />
                                
                                <br/>

                                <label className="txt-small">{hoverDetils["des"]}</label>  
                                
                            </div>
                            <div className="s-h-line hori_center" ></div>
                            <div
                                style={{"width":"10%", "height":"100%"}}
                            ></div>
                            <div className="s-h-icons">
                                {fabricIcons.map((val1, key1)=>{
                                    return(<div style={ isMobile ? {"width":"90%","position":"relative"}:{"width":"90%", "position":"relative"}} className="hori_center">
                                        <label>{val1["des"]}</label>
                                        <label style={{"position":"absolute", "right":"0"}}>
                                        {hoverDetils[val1["attr"]]}
                                        {/* gr/Meter */}
                                        </label>
                                    </div>)
                                })}
                            </div>
                            </div>
                            </>): (
                            <div>
                                
                                <b>{hoverDetils["name"]} , {hoverDetils["brand"]} </b>
                            </div>) }
                        </div>
                    </div>)}

                    {/* {isMobile && (
            
            <div className="burger-menu" onClick={() => setMenuOpen2(!menuOpen2)}>
                        <span className="burger-menu-icon">Fabric</span>
                    </div> )
                    }  */}

            {/* {isMobile && (
                <div className="burger-menu2" onClick={() => setMenuOpen(!menuOpen)}>
                    <span className="burger-menu-icon2">Style</span>
                </div>
            )} */}

                {isMobile && (<div>


                    <StyleP
                        category = {category}
                        sel = {sel}
                        setSel = {setSel}
                        
                        setNav = {setNav}

                        // Styles = {StylesALL}
                        // setStylesAll = {setStylesAll}
                        getStyleImg = {getStyleImg}
                        setLoad = {setLoading}
                        menuOpen={menuOpen}
                        setMenuOpen={setMenuOpen}

                        imgState = {imgState}
                        setImgState=  {setImgState}

                        setIndication= {setIndication}
                        setJodh = {setJodh}

                        jodh={jodh}

                        process={process}
                    />

                    

                   </div>                    
                    )}
                    
                    

                    <SelectFabric
                        category={category}
                        Fabric = {Fabrics_all}
                        useFabric = {useFabric}
                        setUseFabric = {setUseFabric}

                        sel = {sel}
                        setSel = {setSel}
                        
                        sHover = {setHoverDets}
                        hoverState = {setHover}
                        hover = {sHover}

                        nav = {nav}
                        setNav={setNav}

                        setFabricsAll = {setFabricsAll}
                        setFabricTypes= {setFabricTypes}
                        FabricTypes = {FabricTypes}

                        menuOpen2={menuOpen2}
                        setMenuOpen2={setMenuOpen2}
                        menuOpen = {menuOpen}

                        clickHover={clickHover}
                        setClickHover={setClickHover}

                        getStyleImg = {getStyleImg}
                        setStylesAll = {setStylesAll}

                        imgState = {imgState}
                        setImgState = {setImgState}

                        fabSame={fabSame}
                        setFabSame={setFabSame}
                        
                        fabricType = {fabricType}
                        setType = {setType}

                        process={process}
                    />


                    
                    <ViewFabric
                        category = {category}
                        sel = {sel}
                        setSel = {setSel}
                        nav = {nav}
                        setNav = {setNav}

                        loading ={loading}
                        setLoading={setLoading}

                        single = {single}
                        state={state}

                        setStylesAll = {setStylesAll}
                        
                        setMenuOpen = {setMenuOpen}
                        menuOpen = {menuOpen}

                        setMenuOpen2 = {setMenuOpen2}
                        menuOpen2 = {menuOpen2}

                        imgState = {imgState}
                        setImgState = {setImgState}

                        fabricType = {fabricType}
                        setType = {setType}

                        jodh = {jodh}

                        mess = {mess}
                        setMess = {setMess}

                        process={process}
                    />

                    
                    
                </div>)
            : nav == 1 ?
            
                (<div className="StyleBody">
                    
                    {/* {isMobile && (
            
            <div className="burger-menu" onClick={() => setMenuOpen2(!menuOpen2)}>
                        <span className="burger-menu-icon">Fabric</span>
                    </div> )}  */}

{/* {isMobile && (
                <div className="burger-menu2" onClick={() => setMenuOpen(!menuOpen)}>
                    <span className="burger-menu-icon2">Style</span>
                </div>
            )} */}
            

                    {isMobile && (
                        <SelectFabric
                        category={category}
                        Fabric = {Fabrics_all}
                        useFabric = {useFabric}
                        setUseFabric = {setUseFabric}

                        sel = {sel}
                        setSel = {setSel}
                        
                        sHover = {setHoverDets}
                        hoverState = {setHover}
                        hover = {sHover}
                        
                        nav = {nav}
                        setNav={setNav}

                        setFabricsAll = {setFabricsAll}
                        setFabricTypes= {setFabricTypes}
                        FabricTypes = {FabricTypes}

                        menuOpen2={menuOpen2}
                        setMenuOpen2={setMenuOpen2}
                        menuOpen = {menuOpen}
                        
                        clickHover={clickHover}
                        setClickHover={setClickHover}

                        getStyleImg = {getStyleImg}
                        setStylesAll = {setStylesAll}
                        
                        imgState = {imgState}
                        setImgState = {setImgState}

                        fabSame={fabSame}
                        setFabSame={setFabSame}
                    
                        fabricType = {fabricType}
                        setType = {setType}

                        process={process}
                    />
                    )}
                    
                    <StyleP
                        category = {category}
                        sel = {sel}
                        setSel = {setSel}
                        
                        setNav = {setNav}

                        // Styles = {StylesALL}
                        // setStylesAll = {setStylesAll}
                        getStyleImg = {getStyleImg}
                        setLoad = {setLoading}
                        menuOpen={menuOpen}
                        setMenuOpen={setMenuOpen}

                        imgState = {imgState}
                        setImgState=  {setImgState}

                        setIndication= {setIndication}

                        setJodh = {setJodh}

                        jodh={jodh}

                        process={process}
                    />

                    <ViewFabric
                        category = {category}
                        sel = {sel}
                        nav = {nav}
                        setSel = {setSel}
                        setNav = {setNav}

                        loading ={loading}
                        setLoading={setLoading}

                        single = {single}
                        state={state}
                        
                        setStylesAll = {setStylesAll}

                        setMenuOpen = {setMenuOpen}
                        menuOpen = {menuOpen}

                        setMenuOpen2 = {setMenuOpen2}
                        menuOpen2 = {menuOpen2}

                        imgState = {imgState}
                        setImgState = {setImgState}

                        fabricType = {fabricType}
                        setType = {setType}

                        jodh = {jodh}

                        mess = {mess}
                        setMess = {setMess}

                        process={process}
                    />
                </div>)
            : nav === 2 ?
                (<>
                    <NavBar
                        flag={1}
                    />
                    <Measurement
                        nav = {nav}
                        setNav = {setNav}
                        section = {section}
                        setSection = {setSection}
                        type={1}
                    />
                </>
                
                )
            : (<> Nothing Found</>)

            }

                
        </div>
    )

}