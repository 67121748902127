import { useEffect, useState } from 'react';


import heart from '../assets/icon/heart.svg'

import share from '../assets/icon/share.svg'


import upi from '../assets/logo/upi.svg'
import visa from '../assets/logo/visa.svg'
import phonepe from '../assets/logo/phonepe.svg'
import mastercard from '../assets/logo/mastercard.svg'
import rupay from '../assets/logo/rupay.svg'
import google_pay from '../assets/logo/google_pay.svg'
import razorpay from '../assets/logo/razorpay.svg'


import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Carousel, Modal } from 'react-bootstrap';
import play_circle from '../assets/icon/play_circle.svg'
import React from 'react';
import { getDatafromDoc } from '../Firebase/firestoreGet';
import { db } from '../firebase';

import aim from '../assets/samples/anima.gif'
import { ReactSVG } from 'react-svg';

import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';

function Preset_item() {

    let { id } = useParams();

    const navigate = useNavigate()
    const [Data , setData] = useState()
    const location = useLocation()

    const [currH, setCurrH] = useState()

    const [fabDets,setFabDets] = useState()

    const [isMobile, setIsMobile] = useState(window.innerWidth < 480);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 480);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [active, setActive]= useState(0)

    const [loading, setLoading] = useState(true)

    const handleSelect = (selectedIndex) => {
        setActive(selectedIndex);
    };


    useEffect(()=>{
        // console.log("id from path ", id)
        if(id){ 
            const docref = db.collection('Presets').doc(id)
            getDatafromDoc(docref).then((data)=>{
                setData(data)
                setLoading(false)
                getPresetFabric(data)
                // console.log(data)    
            })
        } 
        else if(location && location.state && location.state["sel"]){
            setData(location.state["sel"])
            getPresetFabric(location.state["sel"])
            setLoading(false)
            // console.log(location.state["sel"])
        }
    }, [])
    const [QTY, setQty] = useState(1)

    // useEffect(()=>{
    //     // console.log("Active ; ", active)
    // }, [active])

    const getPresetFabric = (data)=>{

        try{

            var Fabric = {}
            for(let i=0;i<data.fabrics.length;i++){
                if( !Object.keys(Fabric).includes(data.fabrics[i].type) ){
                    Fabric[data.fabrics[i].type] = data.fabrics[i]
                }
            }
            setFabDets(Fabric)
            setCurrH(Object.keys(Fabric)[0])
            
        }catch(e){
            console.log(e)
        }
    }

    const customizeRoute = ()=>{
        try {
            const obj = {
                customize:Data.customize,
                category : Data.category,
                discount : (Number(Data.price)*Number(Data.discount))/100 ,
                fabric : Data.fabric,
                price : Number(Data.price),
                style  : Data.style,
                type: 1,
                types : Data.types,
                name : Data.name ? Data.name : `${Data.category} piece`
            }
            console.log( "presets sel, ", obj )
            sessionStorage.setItem("sel",JSON.stringify(obj))
            navigate(`/custom/${Data.category}/0/customize`)

        } catch(e){
            console.log(e)
        }
    }

    return ( 
    <div className="pre-item">

        {(loading) ?     <div style={{textAlign:"center", position:"fixed", height:"100vh",width:"100vw", backgroundColor:"white", top:"0", left:"0",zIndex:"10", margin:"0"

            }}>
                <img alt src={aim} onerror="this.src='https://d1fufvy4xao6k9.cloudfront.net/images/garment/loading.gif'" className='verti_center'
                    style={{margin:"0"}}
                />
            </div>

        : 
        <div className="pre-sec-wrap">
        {/* <div className='carousel-img-main'> */}
        <div className='carousel-img-main'>
            
            
            <div className='presets_img_status'>
                <div className='FIT_W hori_center' style={{display:"flex", gap:"10px", flexDirection:"column"}}>
                {Data && Data.single && [{"type":1, "src":Data.src}, ...Data.single].map((val, key)=>{
                    return(
                        <div className={`img_list_display ${val.type === 0 ? 'list_d_v' : ''}  `} style={ active === key ? { border:"2px solid #FCA311"} : {}}
                            type="button"
                            onClick={()=>{ setActive(key) }}
                        >
                            
                            <img src={ (Object.keys(val).includes("type") && val.type === 1) ?  val.src : !Object.keys(val).includes("type") ? val :  play_circle }/>
                        </div>
                )
                })
                }
                
                </div>
            </div>

            <Carousel activeIndex={active} variant='dark' fade 
                onSelect={handleSelect} className='c_p_main'>
                {Data && Data.single &&  [{"type":1, "src":Data.src}, ...Data.single].map((val)=>{

                    return(
                        <Carousel.Item
                            interval={ val.type ===0 ? 10000 : 5000 }
                        >
                            <div className="">
                            
                            { (Object.keys(val).includes("type") && val.type === 1) ? 
                            
                            <img src={val.src} className="pre-item-img"/>

                            : ( !Object.keys(val).includes("type")) ? 

                            <img src={val} className="pre-item-img"/>

                            :
                            
                            <video className='' autoPlay muted playsInline loop style={{"width":"100%", "height":"100%", "objectFit":"contain"}}>
                                <source src={val.src} />
                            </video>
                                
                            }
                            </div>


                        </Carousel.Item>
                    )
                })

                }
            </Carousel>
        </div>
            {/* <div>ewfewfwefwefwef</div>
            </div> */}
            <div className="pre-item-right">
                <div className="items-btns-info">
                    <div className="price-info-container">
                        <div className="title-font item-title">
                            {Data ? Data["name"] :"Example Suite" }
                        </div>
                        { (Data && Data.customize) && <div style = {{color:"green"}} ><b>Customizable</b></div>}
                        <div className="item-pricing-sm">
                        
                            {/* {Data ? `${Number(Data["price"]).toLocaleString('en-IN', {
                                maximumFractionDigits: 2,
                                style: 'currency',
                                currency: 'INR'
                            })}` :"Example Suite" } */}
                            <span
                            className={Data && Data.discount && Number(Data.discount) > 0 ? "discount_is" : ""}
                        >{Data ? `${Number(Data.price).toLocaleString('en-IN', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                            style: 'currency',
                            currency: 'INR'
                            })}` :"Example Suite" }
                        </span>
                        
                        {Data && Data.discount && Number(Data.discount) > 0 && 
                        <span className='discount_txt'>
                                {(Number(Data.price) - (Number(Data.price)*Number(Data.discount))/100).toLocaleString('en-IN', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                                style: 'currency',
                                currency: 'INR'
                            }) }
                            {"  "}
                            <span style={{color:"green", fontSize:"1rem"}} >Save {Number(Data.discount)}%</span>
                        </span>
                        }

                        </div>
                    </div>
                    <div className="txt-font item-pricing">
                        <span
                            className={Data && Data.discount && Number(Data.discount) > 0 ? "discount_is" : ""}
                        >{Data ? `${Number(Data.price).toLocaleString('en-IN', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                                style: 'currency',
                                currency: 'INR'
                            })}` :"Example Suite" }
                        </span>

                        &nbsp;&nbsp;
                        {Data && Data.discount && Number(Data.discount) > 0 && 
                        <span className='discount_txt'>
                                {(Number(Data.price) - (Number(Data.price)*Number(Data.discount))/100).toLocaleString('en-IN', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                                style: 'currency',
                                currency: 'INR'
                            })}
                            {"  "}
                            <span style={{color:"#00d000", fontSize:"1rem"}} >Save {Number(Data.discount)}%</span>
                        </span>
                        }
                    </div>

                    <div>
                        <Stack spacing={1}>
                            <Rating name="half-rating-read" defaultValue={4.5} precision={0.5} readOnly />
                        </Stack>
                    </div>

                    <div style = {{color:"green"}}>Estimated Delivery : { (new Date(  (new Date().setDate( (new Date()).getDate() +13 )) )).toDateString() }</div>
                    <div style = {{color:"green"}} >COD Available</div>
                    

                    <b>Item Includes : {fabDets && Object.keys(fabDets).map((val, ind)=>{
                        return(<>
                            { (ind === 1) ? `${val} and ` : `${val} , `}
                            
                        </>)
                    
                    })}</b>

                    {/* {(Data && Data.customize) && <button
                        onClick={()=>{
                            customizeRoute()
                        }}
                    > Customize now </button>} */}
                    

                    {/* <br/><br/> */}
                    <div className="pre-item-des">
                        {Data.des ? Data.des : "Designer Preset Collection" }
                    </div>
                    <div
                        className='hori_center'
                        style={{"display":"flex", "gap":"10px"}}
                    >
                    <div className='quantity item-counter'>
                        <div className='quantity_buts verti_center'>

                            <ReactSVG src={heart} className='qty_butts' type="button"/>
                            {/* <img src={heart} className='qty_butts' type="button"
                            /> */}
                        </div>
                        <div className='qty_des'> <label style={{margin:"0"}} className='verti_center' >Add to Wishlist </label></div>
                        
                    </div>
                    
                    <button className="Butt1 add-cart"
                        onClick={()=>{
                            sessionStorage.setItem('sel',JSON.stringify({...Data, ...{item_type:1}}))
                            navigate("/measurement")
                        }}
                    >
                        <span>Get Measured</span></button>
                    
                        {(Data && Data.customize) &&<button className="Butt1 add-cart"
                        onClick={()=>{
                            customizeRoute()
                        }}
                    >
                        <span>Customize</span></button>}
                    
                        {/* share button */}
                        {/* <div style={{padd1ingRight:"5px",paddingLeft:"5px" ,backgroundColor:"#14213D", borderRadius:"5px"}} ><img src={share} className='share_preset verti_center' /></div> */}
                    
                    {/* <button className='Butt1 add-cart' style={{"marginRight":"3vw"}}><span>Share</span></button> */}
                    </div>
                    {/* <div className="txt-font product-info"><span>Product Info</span></div> */}
                    {/* <div className="pre-product-des">
                        I'm a product detail. I'm a great place to add more information about your product such as sizing, material, care and cleaning instructions. This is also a great space to write what makes this product special and how your customers can benefit from this item.
                    </div> */}
                </div>
                <br/>
                
                <div>
                <div className='txt-font'
                    style={isMobile ? {textAlign:"center"}:{ paddingBottom:"10px", borderBottom:"1px solid #EAEAEA" }}>
                Fabric Details</div>
                {fabDets && currH && 
                <div className='preset_fabric_details'>
                    <div className='p_f_d_t txt-font1'
                        style={{width:"100%"}}
                    >
                        {Object.keys(fabDets).map((val1)=>{return(<div className={ currH === val1 ? "h_p_h p_h_i" : "p_h_i" } 
                            style={{width:`${100/Object.keys(fabDets).length}%`}}
                            onClick={()=>{
                                setCurrH(val1)
                            }}
                            type="button"
                        >{val1}</div>)})}
                    </div>

                    <div className='fab_p' style={{display:"flex", position:"relative"}}>
                        <div className='fab_p_div_1'
                        style={{"width":"55%", marginRight:"5%"}}>
                            <img src={
                             fabDets[currH]["single"] && fabDets[currH]["single"].length>0 ?fabDets[currH]["single"][0] :fabDets[currH]["src"]
                            } className='p_f_d_img'/>
                        </div>
                        <div 
                        className='fab_p_div_2'
                        style={{"width":"40%", display:"flex", flexDirection:"column"}}>
                            
                            <div className={`FIT_H FIT_W fab_p_div_3 ${isMobile ? 'both_center':''}`}
                                style={{display:"flex", flexDirection:"column"}}
                            >
                            {/* <div className='txt-font1 FIT_W'><b>{currH}</b></div><br/> */}
                            <div style={{display:"flex"}} className='FIT_W'>
                                <img src={fabDets[currH].brandImg} style={{"width":"90px"}}/>
                                <span className='p_fab_n'>{fabDets[currH].name}</span>
                            </div>
                            
                            <span className='FIT_W'>{fabDets[currH].material}</span>
                            <span className='FIT_W'>Color :  { fabDets[currH].color.map((vall)=>{ return(<>{vall} &nbsp;</>) }) } </span>
                            <span className='FIT_W'>Pattern : {fabDets[currH].pattern}</span>
                            <span className='FIT_W'>Weave : {fabDets[currH].weave}</span>
                            <span className='FIT_W'>Width : {fabDets[currH].width}</span>
                            <span className='FIT_W'> SKU:4mss3401-01 </span><br/>
                            
                            {!isMobile && <span className='card_des_f hori_center' style={{fontSize:"0.7rem"}}> {fabDets[currH].des.slice(0, 300)} </span>}
                            </div>

                        </div>

                        {isMobile ? <span className='card_des_f hori_center' style={{fontSize:"0.6rem"}}> {fabDets[currH].des.slice(0, 200)} </span> : <></>}
                        
                    </div>
                </div>}
                </div>
                <br/>

                <div class="accordion preset_item_acc hori_center" id="accordionExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Description
                        </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <div> <b> Size & Fit </b> </div>
                            <div> Perfect fit </div>
                            <div>  </div>
                            
                            <br/>
                            <div> <b> Wash Care </b> </div>
                            <div> Machine Wash </div>
                            <br/>
                            <div> <b>Occassion </b> </div>
                            <div>formal wear, Office wear</div>
                            <br/>
                            <div> <b>Accessories and Trim </b> </div>
                            <div> {fabDets && Object.keys(fabDets).map((val, ind)=>{
                        return(<>
                            { (ind === 1) ? `${val} and ` : `${val} , `}
                            
                        </>)
                    
                    })}  </div>
                            <br/><>Product Code : # 3765874654</>

                            <br/> <b>Note : The actual colour of the product may vary slightly due to photographic lighting sources or your device.</b>

                            
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Payment Options
                        </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div class="accordion-body" style={{textAlign:"center"}} >
                        <div className="pay_logos hori_center">
                        
                        <img src={upi} />
                        <img src={visa} />
                        <img src={mastercard} />
                        <img src={rupay} />
                        <img src={google_pay} />
                        <img src={phonepe} />
                        <> +10 </>
                    </div>
                    
                    {/* <div style={{color:"#00a31b", fontWeight:"bold"}} > Offer Applied <b></b> off &nbsp; | &nbsp; You got Free Alteration </div> */}
                    <div style={{color:"green"}} > Get Extra 5 % off on pre-paid Orders</div>
                    <div>Pay full amount with your payment options. You will be directed to Razorpay for Payment</div>
                    
                    <div> 
                        <>Powered by</>
                        <img src={razorpay} style={{width:"100px"}} />
                    </div>
                    <br/>

                    OR 

                    <br/>
                    <div style={{margin:"0", fontSize:"1.5rem"}}> <b>  Cash on Delivery </b></div>
                    <span style={{fontSize:"0.7rem", margin:"0"}} >* Only 500 Token amount at the time of Checkout</span>
                    
                    <br/><br/>
                            
                    



                    {/* <a style={{fontSize:"0.8rem"}}  href="https://maviinci.in/terms">Alteration</a>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <a style={{fontSize:"0.8rem"}}  href="https://maviinci.in/terms">Return and Cancellation policy</a>                             */}
                        </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Alterations and Return Policy
                        </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            Get your clothing Altered free of cost* within 7 days of your order. we will pick up the your cloth, get it altered for your fit. You can Return the cloth within 7 days of Order. Please read through the terms and Conditions for Return and Alteration

                            <br/>

                            <a style={{fontSize:"0.8rem"}}  href="https://maviinci.in/terms">Alteration</a>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <a style={{fontSize:"0.8rem"}}  href="https://maviinci.in/terms">Return and Cancellation policy</a>
                        </div>
                        </div>
                    </div>
                </div>
                
            </div>
            
        </div>
        }
        {/* <div>
            <Foot/>
        </div> */}
    </div> );
}

export default Preset_item;