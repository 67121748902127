import { useEffect, useState } from "react";
import "./presets_main.css"
import { db } from "../firebase";
import { getDocOnCondition } from "../Firebase/firestoreGet";
import ViewImages from "../Fabric/View/viewImages";
import { useNavigate, useParams } from "react-router-dom";
import Order_status from "../Checkout/order_status";
import { getStyleDetails } from "../Functions";

import nextArr from '../assets/icon/nextL.png'
import prevArr from '../assets/icon/next.png'

import nextArr1 from '../assets/icon/Larrow.png'
import prevArr1 from '../assets/icon/Rarrow.png'
import { useSwipeable } from "react-swipeable";

const orderStatus = ["Order Received", "Order Confirmed", "Order Processing", "In Transit", "Order Delivered"]

function Order_page() {


    let { id } = useParams();
    
    const navigate = useNavigate()
    const [Data , setData] = useState()

    const [loading, setLoading] = useState(true)

    const [imgState, setImgState]=  useState(0)
    const [State, setState] = useState(1)

    const [currH, setCurrH] = useState()

    useEffect(()=>{
        if(id){
            const collref = db.collection("Orders")
            getDocOnCondition(collref, "id", id).then((data)=>{
                if(data && data.length>0){
                    setData(data[0])
                    console.log("Order :", data)
                    
                } else {
                    setData([])
                }
                
            })
        }
    }, [])

    useEffect(()=>{
        if(Data){
            
            setLoading(false)
        } 
        if( Data && !Data["Fab"] ){
            setDetails( ).then(()=>{})
        }
        console.log("Order is changing:", Data)
    }, [Data])

    const setDetails = async ( )=>{
        try{  

            const Styles = getStyleDetails( Data, Data.category )

            var Fabric = {}
            const collection = db.collection('Fabric').doc('Suits').collection("fabrics")

            
            if( !Object.keys(Data).includes("item_type") ){

                const types = Object.keys(Data.fabric)
                for(let j=0; j<types.length;j++ ){
                    const data = await getDocOnCondition(collection, "id", Data.fabric[types[j]].fabricID);
                    Fabric[types[j]] = data[0]
                }
                
            } else {
                if(Data.item_type === 1){
                    for(let j=0;j<Data.fabrics.length;j++){
                        Fabric[Data.fabrics[j].type] = {
                            brand : Data.fabrics[j].brand,
                            brandImg : Data.fabrics[j].brandImg,
                            color : Data.fabrics[j].color,
                            material : Data.fabrics[j].material,
                            pattern : Data.fabrics[j].patter,
                            weave : Data.fabrics[j].weave,
                            width : Data.fabrics[j].width,
                            src: Data.fabrics[j].src,
                            name : Data.fabrics[j].name ? Data.fabrics[j].name : ""
                        }
                    }
                } else {
                    Fabric["Fabric"] = {
                        brand : Data.brand,
                        brandImg : Data.brandImg,
                        color : Data.color,
                        material : Data.material,
                        pattern : Data.patter,
                        weave : Data.weave,
                        width : Data.width,
                        src: Data.src,
                        name : Data.name ? Data.name : ""
                    }
                }
            }

            if( Styles && Object.keys(Styles).length > 0 ){
                setData( { ...Data, ...{"Fab":Fabric, "style_des": Styles } } )
            } else {
                setData( { ...Data, ...{"Fab":Fabric } } )
            }
            

            console.log("from details section : ", { ...Data, ...{"Fab":Fabric, "style_des": Styles } } )

            setCurrH( Object.keys(Fabric)[0] )

        } catch(e){
            console.log("from setDetails", e)
        }
    }

    const [isMobile, setIsMobile] = useState(window.innerWidth < 480);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 480);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handlers = useSwipeable({
            onSwipedLeft: () => {
                if (imgState <=
                     State - 1) {
                    setLoading(false);
                    setImgState(curr => curr + 1);
                    // setInitial(false)
                }
            },
            onSwipedRight: () => {
                if ( ( !(Data.category ==="kurta" && (Data.types.includes("Coat") || Data.types.includes("Sadri") ) ) && imgState > 0) 
                    || ( (Data.category ==="kurta" && (Data.types.includes("Coat") || Data.types.includes("Sadri") ) ) &&  imgState > -1 ) ) {
                    setLoading(false);
                    setImgState(curr => curr - 1);
                    // setInitial(false)
                }
            }
        });

    return ( 
    <div className="pre-item">

        {(loading) ?     <div style={{textAlign:"center", position:"fixed", height:"100vh",width:"100vw", backgroundColor:"white", top:"0", left:"0",zIndex:"10", margin:"0"

            }}>
                {/* <img alt src={aim} onerror="this.src='https://d1fufvy4xao6k9.cloudfront.net/images/garment/loading.gif'" className='verti_center'
                    style={{margin:"0"}}
                /> */}
                loading ....
            </div>

        : Data  ?
        <div className="pre-sec-wrap">
        {/* <div className='carousel-img-main'> */}
        <div className='carousel-img-main1'>
            
            
                <ViewImages
                    sel = {Data}
                    loading = {loading}
                    setLoading={setLoading}
                    imgState={imgState}
                    setState1 = {setState}
                    setImgState={setImgState}
                    type = {1}
                    category={Data.category}
                />
                <div className="arrow-d">
                <img src={isMobile ? nextArr1 : nextArr} className='v-arr-l'
                                            type = "button"
                                            style={ imgState <=0 ? {display:"none"}:{}}
                
                                            onClick={()=>{
                                                if( ( !(Data.category ==="kurta" && (Data.types.includes("Coat") || Data.types.includes("Sadri") ) ) && imgState > 0) 
                                                    || ( (Data.category ==="kurta" && (Data.types.includes("Coat") || Data.types.includes("Sadri") ) ) &&  imgState > -1 ) ){
                                                    // console.log("Clicking")
                                                    setLoading(false)
                                                    setImgState(curr => curr - 1);
                                                    // setInitial(false)
                                                }
                                                // setImgState((curr)=>(    
                                                //     curr > 0 ? curr - 1 : 0)
                                                // )
                                            }}
                                        />
                                    <img src={isMobile ? prevArr1 : prevArr} className='v-arr-r'
                                            type = "button"
                                            style={ imgState >= State ? {display:"none"}:{}}
                
                                            onClick={()=>{
                                                if(imgState < State-1){
                                                    // console.log("clicking")
                                                    setLoading(false)
                                                }
                                                
                                                
                                                setImgState((curr)=>(
                                                curr < State ? curr + 1 : curr
                                                ))
                                                // setInitial(false)
                                    }}
                                            
                                        />
                                </div>
        </div>
            {/* <div>ewfewfwefwefwef</div>
            </div> */}
            <div className="pre-item-right">
                <div className="items-btns-info">
                    <div className="price-info-container">
                        <div className="title-font"> Order Id : {Data.id} </div>
                        <div className="title-font item-title">
                            {Data && Data.name ? Data["name"] :"Example Suite" }
                        </div>
                        
                    </div>

                    <div className="txt-font1">Total : 
                        { Object.keys(Data).includes("item_type") && Data.item_type === 0 ? 
                        <>
                            {Number(250).toLocaleString('en-IN', {
                                maximumFractionDigits: 2,
                                style: 'currency',
                                currency: 'INR'
                                })}</>
                            :
                        
                            <>{Number(Data.price).toLocaleString('en-IN', {
                            maximumFractionDigits: 2,
                            style: 'currency',
                            currency: 'INR'
                            })}</>
                            }
                    </div>

                    <div className="txt-font1">Status : {Data.status ? orderStatus[Data.status.status] :"Order Received"}</div>
                    
                    <br/><Order_status status={ Data.status ? Data.status.status : 0  }/>
                        
                    {/* <b>Item Includes : {fabDets && Object.keys(fabDets).map((val, ind)=>{
                        return(<>
                            { (ind === 1) ? `${val} and ` : `${val} , `}
                            
                        </>)
                    
                    })}</b> */}

                    <div className="order_Address"> 
                        <div className="o-d-add">
                            <b>Delivery Adddress  </b>
                            <div>{Data.Address?.name}</div>
                            <div>{Data.Address?.phone}</div>
                            <div>{Data.Address?.address}</div>
                            <div>{Data.Address?.city}</div>
                            <div>{Data.Address?.state} | <div>{Data.Address?.pincode}</div></div>

                        </div>
                        <div className="o-b-add">
                            <b>Billing Adddress  </b>
                            <div>{Data.Address?.name}</div>
                            <div>{Data.Address?.phone}</div>
                            <div>{Data.Address?.address}</div>
                            <div>{Data.Address?.city}</div>
                            <div>{Data.Address?.state} | <div>{Data.Address?.pincode}</div></div>
                        </div>
                    </div>

                    <div className="txt-font">Fabric Details</div>

                    <div>
                    {Data && Data?.Fab && 
                    <div className='preset_fabric_details'>
                        <div className='p_f_d_t txt-font1'
                            style={{width:"100%"}}
                        >
                            {Object.keys(Data.Fab).map((val1)=>{return(<div className={ currH === val1 ? "h_p_h p_h_i" : "p_h_i" } 
                                style={{width:`${100/Object.keys(Data.Fab).length}%`, border:"1px solid #EAEAEA"}}
                                onClick={()=>{
                                    setCurrH(val1)
                                }}
                                type="button"
                            >{val1}</div>)})}
                        </div>
    
                        <div style={{display:"flex"}}>
                            <div style={{"width":"45%", marginRight:"5%"}}><img src={Data.Fab[currH]["src"]} className='p_f_d_img'/></div>
                            <div style={{"width":"50%", display:"flex", flexDirection:"column"}}>
                                <b><span>{Data.Fab[currH]?.name}</span></b>
                                <span> <img src={Data.Fab[currH].brandImg} style={{"width":"80px"}}/> {Data.Fab[currH].brand}</span>
                                <span>{Data.Fab[currH].material}</span>
                                <span>Weave : {Data.Fab[currH].weave}</span>
                                <span>Weight : {Data.Fab[currH].weight}</span>
                            </div>
                        </div>
                    </div>
                    }
                </div>
                
                {Data && Data["style_des"] && 
                <div>
                <div className="txt-font"
                    
                > 
                    Style Selection </div>
                        <div>
                            <div style={{"width":`${Data["style_des"][currH].slice(0, 5).length*20}%`, "display":"flex", "position":"relative"}}
                                className='hori_center'

                            >
                                { Data["style_des"] && Data["style_des"][currH].slice(0, 5).map((sty)=>{
                                    return(
                                        <div className='single_s_des'
                                            style={{"width":`${100/Data["style_des"][currH].slice(0, 5).length}%`}}
                                        >
                                            <img src={sty.img} className='hori_center' />
                                            <b><label>{sty.type}</label></b>
                                            <label>{sty.name}</label>
                                            
                                        </div>
                                    )
                                })
                                }
                            </div>
                            {/* // */}
                            <div style={{"width":`${Data["style_des"][currH].slice(5).length*20}%`, "display":"flex", "position":"relative"}}
                                className='hori_center'

                            >
                                { Data["style_des"] && Data["style_des"][currH].slice(5).map((sty)=>{
                                    return(
                                        <div className='single_s_des'
                                            style={{"width":`${100/Data["style_des"][currH].slice(5).length}%`}}
                                        >
                                            <img src={sty.img} className='hori_center' />
                                            <b><label>{sty.type}</label></b>
                                            <label>{sty.name}</label>
                                            
                                        </div>
                                    )
                                })
                                }
                            </div>
                            
                        </div>
                    

                </div>
                }
                

                    
                </div>
                
                
            </div>
            
        </div> : 
        <div> No Data found </div>
        }
        {/* <div>
            <Foot/>
        </div> */}
    </div> );
}

export default Order_page;