import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../Firebase/AuthProvider";
import { db } from "../firebase";
import { getData } from "../Firebase/firestoreGet";
import { useNavigate } from "react-router-dom";

const measure_profile = {
    "u":[ 
    {"value":"length","name":"Length"},
    {"value":"chest_around","name":"Chest"},
    {"value":"stomach","name":"Stomach"},
    {"value":"hip","name":"Hip"},
    {"value":"shoulder_width","name":"Shoulder"},
    {"value":"sleeve_length","name":"Sleev Length"},
    {"value":"neck","name":"Neck"},
    {"value":"bisep","name":"Bisep"}],
    "l":[
        {"value":"leg_length","name":"Leg Length"},
        {"value":"waist","name":"Waist"},
        {"value":"bottom","name":"Bottom"},
        {"value":"hip","name":"Hip"},
        {"value":"thigh","name":"Thighs"},
        {"value":"circle","name":"Circle"}
    ],
}

function SavedM({setSelM, sel_m, cat}) {
    const [loading, setLoading] = useState(true)
    const { user } = useContext(AuthContext);

    const [svd, setSvd] = useState()

    const [isMobile, setIsMobile] = useState(false)

    // const [sel_m, setSelM] = useState()

    const [expand, setExpand] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 480); // Adjust the width as needed
        };

        // Initial check
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Clean up event listener on unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(()=>{
        
        if(user){
            let usrid = user.email
            if(!usrid){
                usrid = user.phoneNumber
            }

            const collection = db.collection('Users').doc('measurements').collection(usrid)
            console.log("getting M for ", usrid)
            getData(collection).then((data)=>{
                const final = []
                data.forEach((ele)=>{
                    if(checkValid(ele)){
                        final.push(ele)
                    }
                })
                setSvd( final )
                console.log(final)
                if(final.length > 0){
                    setSelM( {...final[0], ...{key:0}} )
                }
            })
        } else {
            navigate( "/login" , {state:{from:"measurement/saved"}})
        }
    }, [user])

    const checkValid = (obj)=>{
        if(cat === 1){
            return Object.hasOwn( obj, "lower_body" )
        } else if(cat === 2){
            return (Object.hasOwn( obj, "lower_body" ) && Object.hasOwn( obj, "upper_body" ))
        } else {
            return Object.hasOwn(obj,  "upper_body" )
        }
    }

    const addToCart = ()=>{
        
            let data = JSON.parse(sessionStorage.getItem("sel"))
            sessionStorage.removeItem("sel")

            data["measurements"] = sel_m

            if( Object.hasOwn(data, "id") ){
                let local = JSON.parse( localStorage.getItem("sel"))
                local.forEach((k, i)=>{
                    if(k["id"] === data["id"]){
                        local[i] = data
                    }
                })
                localStorage.setItem("sel", JSON.stringify(local))
            }else{
                let currC = localStorage.getItem("sel")
                if(currC && JSON.parse(currC).length > 0){
                    let upC = JSON.parse(currC)
                    // console.log("curr",upC)
                    let c = upC[upC.length - 1]["id"] + 1
                    data["id"] = c
                    upC.push(data)
                    
                    localStorage.setItem("sel", JSON.stringify(upC))
                }else{
                    data["id"] = 1
                    localStorage.setItem("sel", JSON.stringify([data]))
                }
            }    
            // console.log("data", JSON.parse(localStorage.getItem("sel")))
        
            navigate('/cart');

    }
    return (  
    <>
    { user &&

    
    <div className="svd_measurements hori_center">   
        <div className="title-font">Choose From Saved Measurements</div>
        <div> Select from one of your saved measurements from your previous orders and we will take care of the rest </div>
        <br/>
        
        { !svd ?
            (<>loading...</>)
        : svd && svd.length>0 ? (
            <>
                <div className="svd_section">
                    <div className="svd_leftsection">
                        {svd.map((val, key)=>{
                            return(
                                <div className="svd_m_row_ele"
                                    style={val.doc === sel_m.doc ? { "border":"1px solid #14213d" } : {}}
                                >
                                    <div className="svd_m_row_ele_top" type="button" 
                                        onClick={()=>{
                                            setSelM({...val, ...{key:key}}); 
                                        }}
                                        >
                                        <div className={val.doc === sel_m.doc ? "svd_round_fill" : "svd_round"}> </div> 
                                        <div>{key+1}&nbsp;&nbsp;&nbsp;.&nbsp;{val.name} Measurement  </div>

                                        {isMobile && ( <div onClick={()=>{setExpand((curr)=>!curr)}} > {expand ? "D": "U"}</div> ) }
                                    </div>
                                    {(expand && val.doc === sel_m.doc) && <div className="svd_m_row_ele_bot" > 
                                        <br/>
                                        {sel_m.age && <div> Age : {sel_m.age}  </div>}
                        {sel_m.height && <div> Height : {sel_m.height}  </div>}
                        {sel_m.weight && <div> Weight : {sel_m.weight}  </div>}
                        <br/>
                        {sel_m.type === 1 ? 
                            <div style={{
                                width:"100%",
                                display:"flex",
                                gap:"10px"
                            }} className="hori_center" >
                                {sel_m?.upper_body && <div className="order_m_m_l"
                                    style={{width:"50%",
                                        display:"flex",
                                        flexDirection:"column"
                                    }}
                                >
                                    <b>Upper Body</b>
                                    {measure_profile.u.map((val)=>{
                                        return(<label>{val["name"]} : <b>{sel_m.upper_body[val["value"]]}</b> IN </label>)
                                    })}
                                    
                                </div>}
                                {sel_m?.lower_body && <div className="order_m_m_r"
                                    style={{width:"50%",
                                        display:"flex",
                                        flexDirection:"column"
                                    }}
                                >   
                                    <b>Lower Body</b>
                                    {measure_profile.l.map((val)=>{
                                        return(<label>{val["name"]} : <b>{sel_m.lower_body[val["value"]]}</b> IN </label>)
                                    })}
                                </div>}
                            </div>
                            : <div>

                                <label> <b> Upper Body : </b> {sel_m.upper_body} IN </label><br/>
                                <label> <b>Lower Body : </b> {sel_m.lower_body} IN </label><br/>
                                
                            </div>

                        }
                                    </div>}
                                </div>
                            )
                        })

                        }
                    </div>
                    
                    {(sel_m && !isMobile) && 
                        <div className="svd_rightsection txt-font1">
                        <div className="txt-font" style={{"textAlign":"center", position:"relative"}}> 
                            <div className=""><b> {sel_m.key+1}. {sel_m.name} Measurement </b> </div>
                            <div style={{position:"absolute", right:"0"}}>
                                <button className="Butt1" style={{padding:"2px 10px"}} 
                                    onClick={()=>{ addToCart() }}
                                > <span>Continue</span> </button>
                            </div>
                        </div>
                        <br/>
                        {sel_m.age && <div> Age : {sel_m.age}  </div>}
                        {sel_m.height && <div> Height : {sel_m.height}  </div>}
                        {sel_m.weight && <div> Weight : {sel_m.weight}  </div>}
                        <br/>
                        {sel_m.type === 1 ? 
                            <div style={{
                                width:"100%",
                                display:"flex",
                                gap:"10px"
                            }} className="hori_center" >
                                {sel_m?.upper_body && <div className="order_m_m_l"
                                    style={{width:"50%",
                                        display:"flex",
                                        flexDirection:"column"
                                    }}
                                >
                                    <b>Upper Body</b>
                                    {measure_profile.u.map((val)=>{
                                        return(<label>{val["name"]} : <b>{sel_m.upper_body[val["value"]]}</b> IN </label>)
                                    })}
                                    
                                </div>}
                                {sel_m?.lower_body && <div className="order_m_m_r"
                                    style={{width:"50%",
                                        display:"flex",
                                        flexDirection:"column"
                                    }}
                                >   
                                    <b>Lower Body</b>
                                    {measure_profile.l.map((val)=>{
                                        return(<label>{val["name"]} : <b>{sel_m.lower_body[val["value"]]}</b> IN </label>)
                                    })}
                                </div>}
                            </div>
                            : <div>

                                <label> <b> Upper Body : </b> {sel_m.upper_body} IN </label><br/>
                                <label> <b>Lower Body : </b> {sel_m.lower_body} IN </label><br/>
                                
                            </div>

                        }
                        

                    </div>
                    }

                    
                </div>
            </>
        )
        : (<>
            You do not have any saved Measurements
            <button className="Butt1"> <span> Add now </span> </button>
            </>)

        }
        
        

    </div> 
    }
    </>
    );   
}

export default SavedM;
