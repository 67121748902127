import React, { useState, useEffect } from 'react';
import './App.css';
import phone from './assets/phone.svg';
import cart from './assets/cart.svg';
import acc from './assets/acc.svg';
import Logo from './assets/Logo.png';
import Logo1 from './assets/download.svg';

import burger_icon from './assets/icon/burger_left.svg'

import { useNavigate } from 'react-router-dom';
import { logout, getState } from './Firebase/auth';
import MobileNavBar from './MobileNavbar';
import Patti_home from './Homepage/Home/patti';
import SidebarNav from './navslider';

import { motion } from "framer-motion";

export default function NavBar({ flag = 1 }) {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);

  const [sidebar, setSideBar] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let oldScrollY = 0;

  const [direction, setDirection] = useState('up');

  const [isSticky, setIsSticky] = useState(false);

  const [Style, setStyle] = useState( { background: 'transparent' } )

  const controlDirection = () => {

      if (!isMobile && window.scrollY > 500 && window.scrollY > oldScrollY) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }

      oldScrollY = window.scrollY;
  }

  useEffect(()=>{
      if(isSticky){
        setStyle(
          {
            background : "#14213D",
            position : "fixed"
          }
        )
      } else {
        setStyle(
          {
            background : "transparent",
            position : "absolute"
          }
        )
      }
  }, [isSticky])

  useEffect(() => {
      window.addEventListener('scroll', controlDirection);
      return () => {
          window.removeEventListener('scroll', controlDirection);
      };
  },[]);

  const accountClick = () => {
    if (getState()) {
      navigate('/profile');
    } else {
      navigate('/login');
    }
  };

  return (
    <motion.nav
      initial="hidden"
      whileInView="visible"
      viewport={{ once: false }}
      transition={{ duration: 0.5 }}
      variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 }
      }}
      className= {`NavBar ${(flag ===3 && !isSticky) ? 'patti_nav':''} `}
      style={
        (flag === 0 || flag === 3)  ? Style : flag === 2 ? { position: 'fixed' } : {}
      }
    >
      
      <div className='nav_wrapper' style={sidebar? {display:"block", "opacity":"1"}:{}} 
        onClick={()=>{setSideBar(false)}}
      ></div>
        <SidebarNav sidebar={sidebar} setSideBar ={setSideBar} />
      
      
      {(flag ===3 && !isSticky) && <Patti_home/>}
      <div className="Navbody txt-font">
        {isMobile ? (
          // Mobile View
          <MobileNavBar sidebar={sidebar} setSideBar ={setSideBar} />
        ) : (
          // Desktop View

          
          <div className="nav-desktop">
            <div className="nav-left txt-font1">
              <div type="button">
                <img src={burger_icon} style={{width:"30px"}} type="button" className='sidenav_burger'
                  onClick={()=>{setSideBar(!sidebar)}}
                />
                
                {/* Home */}
              </div>
              {/* <div onClick={() => navigate('/')} type="button">
                About Us
              </div>
              <div onClick={() => navigate('/feel')} type="button">
                Feel Fabric
              </div> */}
            </div>

            <div>
            <a href="/" style={{textDecoration:"none"}}>
              <img src={Logo1} alt="Logo" className='logo_main' onClick={()=>{}} type="button" />
              </a>
            </div>

            <div className="nav-right txt-font1">
            <a href="/contact" style={{textDecoration:"none"}}>
              <img src={phone} className="nav-logos" alt="Phone" 
                type="button"
                onClick={()=>{}}
              />
              </a>
              <a href="/cart" style={{textDecoration:"none"}}>
              <img
                type="button"
                src={cart} className="nav-logos" alt="Cart"
                onClick={() => {}}
              />
              </a>
              
              <a style={{textDecoration:"none"}}>
              <img
                type="button"
                src={acc} className="nav-logos" alt="Account"
                onClick={accountClick}
              />
              </a>
            </div>
          </div>
        )}
      </div>
    </motion.nav>
  );
}
