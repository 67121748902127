import { StylesFilter, StylesFilter_Blazer, StylesFilter_kurta } from "../Data"
import { db } from "../firebase"
import { addData } from "../Firebase/firestore"
import { getData } from "../Firebase/firestoreGet"

const backendURL = "https://suits-backend.vercel.app"
// const backendURL = "http://localhost:1337"

export const AppointReq = async(data)=>{
    try{
        const headers = {
            "Content-Type" : "application/json"
        }        

        
        
        const collection = db.collection("Users").doc("Requests").collection(data["email"])
        await addData(collection, "", data);
        const resp = await fetch(`${backendURL}/appointment`, {method : 'POST',headers:headers,body:JSON.stringify(data)})
        const res = await resp.json()

        return res.status === 0 ? true : false
    }catch(e){
        console.log(e)
    }
}


export const senEmail = async (params)=>{
        
    const headers = {
        "Content-Type" : "application/json"
    }
    const resp = await fetch('https://suits-backend.onrender.com/sendMail', {method : 'POST',headers:headers,body:JSON.stringify(params)})
    const res = await resp.json()

    return res.status === 0 ? true : false


}


export const postOrder = async(order)=>{

    try{
        console.log("POST Order")

        let orders = JSON.parse(localStorage.getItem('sel'))

        var address = JSON.parse(localStorage.getItem('a'))

        address = address["delivery"]
        

        console.log("Orders" , orders)

        if(orders){ 
            // // console.log("Checking order details",order)
            for(let i = 0;i<orders.length;i+=1){
                orders[i]["payment_order_id"] = order["razorpay_order_id"]
                orders[i]["payment_id"] = order["razorpay_payment_id"]
                orders[i]["Address"] = address
                orders[i]["status"] = { status:0, Date : new Date() }
            }
            // console.log("dumping in DB", orders)
            
            const collection = db.collection('Orders')
            var batch = db.batch();
            // console.log("passing this")
            const email = order.email
            const curr = new Date()

            const OrderID = curr.getTime().toString()+"M"
            var i = 0
            orders.forEach((doc) => {
                i = i+1
                const ID = (new Date().getTime()).toString()
                // console.log("ID from orders, ", ID)
                const finalOrder = {...doc, ...{"Date":Date(), "id":ID, "email":email, "orderId":OrderID}}
                batch.set(collection.doc(ID), finalOrder);
            })
            const result = await batch.commit()
            
            // paymentStatus({...payment, "id":1})

            localStorage.removeItem('sel')
            localStorage.removeItem('a')

            const pramsData = {
                "email" : email,
                "name" : address.name,
                "orderId" :  OrderID ,
                "items" : i.toString(),
                "price" : order["price"]
            }
            // setcheck({...checkoutData, ...pramsData})
            
            console.log("User Detaisl", pramsData)
            
            senEmail(pramsData).then(()=>{})
            // setLoading(false)
            // console.log("postOrder", result)

            return {...pramsData, ...{status: 1 } }
        }else{
            // console.log("No orders")
            // paymentStatus({...payment, "id":-1})
            // return error
            return {status: 0 } 
        }
    } catch(e){
        return {status: 0 } 
        // console.log("Error",e)
        // paymentStatus({...payment, "id":-1})
    }
}


export const paymentVerification = async(response, atype = 0)=>{
    try{
        // console.log("Verification : ", response)
        const params = {
            "razorpay_payment_id": response.razorpay_payment_id,
            "razorpay_order_id": response.razorpay_order_id
        }
        const headers = {
          "x-razorpay-signature":  response.razorpay_signature,
          "Content-Type" : "application/json"
        }

        const options = {
            method : 'POST'
        }
        
        
        const resp = await fetch(`${backendURL}/verification`, {method : 'POST', headers:headers, body:JSON.stringify(params)})
        const res = await resp.json()
        
        return {...res, ...params};
        // console.log("after payent",res)
        // console.log("Payment Status : ", {
        //     "id" : 0, 
        //     "status": res["status"] === 200
        // })
        // paymentStatus({
        //     "id" : 0, 
        //     "status": res["status"] === 200
        // })
        
        
    }catch(e){
        // console.log("verification e", e)
    }
}


export const checkCategory =(category)=> {
    if(category === "suit" || category === "kurta"){
        return true
    } else {
        return false
    }
}

export const changeImgState = (sel, type, pos, setImgState, setLoad, category, imgState, style)=>{
    try{
        var l = 0;

        if(!checkCategory(category)){
            if(category === "formal"){
                if(type === "Shirt"){

                    if(pos === "F"){
                        if(imgState !=0 || style === "Cuffs"){
                            setImgState(1)
                            setLoad(false)
                        } else{
                            l = 1;
                        }
                        
                    }else{
                        setImgState(2)
                        setLoad(false)
                    }
                } else {
                    if(pos === "F"){
                        if(imgState !== 0){
                            setImgState(3)
                            setLoad(false)
                        } else {
                            l=1;
                        }
                        
                    }else{
                        setImgState(4)
                        setLoad(false)
                    }
                    
                }
            } else if (category === "blazer"){
                
                if(pos === "F"){
                    // if(imgState === 0){
                    
                    setImgState(imgState ===2 ? 1 : imgState)
                    l = 1;
                    // }
                    // setImgState(1)
                } else {
                    setImgState(2)
                }
                
            } else if(category === "sadri"){
                l = 1;
            }
            else {
                if(pos && pos === "F"){
                    setImgState(1)
                    setLoad(false)
                }else {
                    setImgState(2)
                    setLoad(false)
                }
            }
            
            
        } else{
            
            if(category === "suit"){
                if(type === "Coat"){

                    if(pos === "F"){
                        setImgState(1)                             
                    } else {
                        setImgState(2)
                    }   
                    setLoad(false)
                }
                if(type === "Shirt"){
                    // setLoad(true)
                    setImgState(3)
                    setLoad(false)                                                          
                }
                
                if(type === "Vest"){
                    // setLoad(true)
                    var i = 0
                    if(sel && sel.types && sel.types.includes("Shirt")){
                        i = 6
                    }else{
                        i = 5
                    }
                    setImgState(i)
                    setLoad(false) 
                }
    
                if(type === "Pant"){
                    var i = 0
                    if(sel && sel.types && sel.types.includes("Shirt")){
                        i = 4
                    }else{
                        i = 3
                    }
                    // setLoad(true)
                    if(pos === "F"){
                        setImgState(i)                                                        
                    } else {
                        setImgState(i+1)
                    }
                    setLoad(false)   
                }
            } else if( category === "kurta" ){
                if(type === "Pajama"){
                    if(imgState <=0 && style === "Style"){
                        l = 1;
                    }
                    else if(sel.types.includes("Coat")){
                        if(imgState === 4){
                            l = 1;
                        } else {
                            setImgState(4)
                        }
                        // setLoad(false)
                    } else if(sel.types.includes("Sadri")){
                        if(imgState === 2){
                            l = 1;
                        } else {
                            setImgState(2)
                        }
                    } else {
                        if(imgState === 2){
                            l = 1;
                        } else {
                            setImgState(2)
                        }
                        
                    }
                }
                if(type === "Kurta"){
                    if(imgState === 0 || imgState === 1){
                        if(style === "Cuff Style" && imgState === 0){   
                            setImgState(1)
                        } else {
                            l = imgState + 1 // should only work for 0
                        }
                        
                    } else if(imgState <0 && 
                        ( (style === "Sleeve" && sel.types.includes("Sadri"))
                            || style === "Bottom Cut")
                        ){
                            l = 1;
                    }
                    else{
                        if(style === "Cuff Style"){
                            setImgState(1)
                        } else{
                            setImgState(0)
                            l = 1;
                        }
                        // setLoad(false)
                    }
                    
                }
                if(type === "Coat" || type === "Sadri"){
                    
                    if(imgState === -1 || 
                        (imgState === 2 && type === "Coat") 
                        || (imgState === 3 && type === "Coat") 
                    ){
                        
                        if( ( imgState === -1 || imgState === 2) && pos === "B"){  
                            setImgState(3)
                        }
                        if(imgState === 3 && pos === "F" && type === "Coat"){
                            setImgState(2)
                        }
                        if(imgState === -1 && pos != "B"){
                            l =1 
                        }
                        // l = imgState + 2 // should only work for -1;
                    } else {
                        if(pos === "F"){
                            if(type === "Sadri"){
                                setImgState(3)
                                l=1
                            } else{
                                setImgState(2)
                            }
                            
                        } else {
                            setImgState(3)
                        }
                    }
                }
            }
        
            
        }

        return l;
    } catch(e){ 
        console.log("From changeImgState function", e)
    }
}

export const checkStyleConditions=(sel, value, jodh=null, val1 = null)=>{
    try{    
        
        let condition = false
        const styleF = sel.style[value.name].F
        const styleB = sel.style[value.name].B

        if(value.name === "Kurta"){
            
            if((value.type === "Pocket Type" && styleF[2] === "00")
                || (value.type === "Flap Type" && styleF[4] === "0")
                || (value.type === "Cuff Style" && styleF[8] === "SN")
                || (value.type.includes("Flap") && styleF[2] === "00" )
                || ( ["CR", "CB", "CT", "CS", "CE"].includes(styleF[7]) && ["NS4", "QS4"].includes(val1?.value) )
                || ( !["CR", "CB", "CT", "CS", "CE"].includes(styleF[7]) && ["NS3", "QS3"].includes(val1?.value) )
            ){
                condition = true
            } 
        }
        if(value.name === "Pajama"){

            if(value.type === "Belt" && styleF[0] === "PP"){
                condition = true;
            }
        }

        if(value.name === "Coat"){
            if(value.type === "Stitch" && val1.value === "Y" && styleF[0][0] === "J"){
                condition = true;
            }
            if((value.type === "Lapel" || value.type === "Pocket" ) && styleF[0] === "JA" ){
                condition = true;
            }
        }
        if(condition) {return condition}


        condition = (jodh && value["type"]=== "Lapel") 
        || (jodh && value["type"] === "Front Pocket" && val1?.value === "R") 
        || (value["type"] === "Front Pocket" && val1?.value === "R" && sel.style.Pant && sel.style.Pant.F[0] === "F-NP" && sel.style.Pant.F[2] === "G" )
        || (value["type"] === "Front Pocket" && val1?.value === "R" && sel.style.Pant && sel.style.Pant.F[0] === "F-SP")
        || (value["type"] === "Belt Style" && sel.style.Pant && sel.style.Pant.F[0] === "F-DP" && val1?.value === "H")
        || (value["type"] === "Belt Style" && sel.style.Pant && sel.style.Pant.F[0] === "F-NP" && val1?.value === "G" &&  sel.style.Pant.F[1] === "R")
        || ( value["type"] === "Cuffs" && sel.style.Shirt && sel.style.Shirt.F[3] === "H" )

        

        

        return condition
    } catch(e){

    }
}

export const getStyleDetails = (sel, category) => {
        try{
            var StylesDets = {}
            const Stylesfilter_to_use = category === "kurta" ? StylesFilter_kurta
                                        : category === "blazer" ? StylesFilter_Blazer
                                        : category === "sadri" ? StylesFilter_kurta
                                        : StylesFilter
            // console.log("coming here in Styles des funct")
            if(sel && sel.style){
                    
                Object.keys(sel["style"]).forEach((t)=>{
                    StylesDets[t] = []
                    Object.keys(sel["style"][t]).forEach((side)=>{
                        
                        var value = null;
                        var img = null;
                        sel["style"][t][side].forEach((val, k)=>{
                            if( (t!=="Shirt") 
                                || (t === "Shirt" && val!= "BASE" && val!="SLEVE")
                                
                            ){
                            
                            var r = null
                            
                            if(t === "Coat" && side === "F" && val[0] === "J"){
                                r = Stylesfilter_to_use.find((filter) => filter.type === "Jodhpuri");
                                console.log("r:", r)
                            } else{
                                r = Stylesfilter_to_use.find((filter) => filter.name === t && filter.pos === side && filter.n === k);
                            }
                            
                            if(!r){
                                return
                            }
                            // console.log(r, side, t)
                            if( checkStyleConditions(sel, r) ){
                                return
                            }
                            // console.log("from inside", r["attr"])
                            for(let i = 0;i<r["attr"].length;i++){
                                if(value){break}
                                if( Array.isArray(r["attr"][i])){
                                    for(let j = 0;j<r["attr"][i].length;j++){
                                        // console.log("of a type:", r["attr"][i][j])
                                        if(r["attr"][i][j]["value"] === val){
                                            value = r["attr"][i][j]["name"]
                                            img = r["attr"][i][j]["img"]
                                            break    
                                        }
                                    }
                                }else{
                                    if(r["attr"][i]["value"] === val){
                                        value = r["attr"][i]["name"]
                                        img = r["attr"][i]["img"]
                                        break
                                    }
                                }
                            }
                            
                            if(value){
                                StylesDets[t].push( {"type":r["type"], "name":value, "img":img})
                            }
                            
                            //  `${r["type"]} : ${value}`
                            value = null
                            }
                        })
                    })
                })

                
            } 
            return StylesDets
        } catch(e){
            console.log(e)
        }
    }







