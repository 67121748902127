import './App.css'

function Terms() {
    return ( 
    <div className='Terms_Page'>
        
        For the purpose of these Terms and Conditions, The term "we", "us", "our" used anywhere on this page shall mean Blue Pearl, whose registered/operational office is Ward 15 indira para, shanti nagar bhilai Durg CHATTISGARH 490021 . "you", “your”, "user", “visitor” shall mean any natural or legal person who is visiting our website and/or agreed to purchase from us.

        Your use of the website and/or purchase from us are governed by following Terms and Conditions:

        <ul style={{listStyleType:"square"}} >
        {/* list-style-type: square; */}
            <li>The content of the pages of this website is subject to change without notice.</li>
            <li>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</li>
            <li>Your use of any information or materials on our website and/or product pages is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through our website and/or product pages meet your specific requirements.</li>
            <li>Our website contains material which is owned by or licensed to us. This material includes, but are not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</li>
            <li>All trademarks reproduced in our website which are not the property of, or licensed to, the operator are acknowledged on the website.</li>
            <li>Unauthorized use of information provided by us shall give rise to a claim for damages and/or be a criminal offense.</li>
            <li>From time to time our website may also include links to other websites. These links are provided for your convenience to provide further information.</li>
            <li>You may not create a link to our website from another website or document without Blue Pearl’s prior written consent.</li>
            <li>Any dispute arising out of use of our website and/or purchase with us and/or any engagement with us is subject to the laws of India .</li>
            <li>We, shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time</li>
        </ul>


        <h3>Privacy Policy</h3>
Last updated on Aug 30th 2024<br/><br/>
This privacy policy sets out how Blue Pearl uses and protects any information that you give Blue Pearl when you visit their website and/or agree to purchase from them.
Blue Pearl is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, and then you can be assured that it will only be used in accordance with this privacy statement.
Blue Pearl may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you adhere to these changes.
<br/>
<b>We may collect the following information:</b><br/>
<ul>
<li>	Name</li>
<li>	Contact information including email address</li>
<li>	Demographic information such as postcode, preferences and interests, if required</li>
<li>	Other information relevant to customer surveys and/or offers</li>
</ul>
<b>What we do with the information we gather</b><br/>
We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:
<ul>
<li>	Internal record keeping.</li>
<li>	We may use the information to improve our products and services.</li>
<li>	We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided.</li>
<li>	From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone, fax or mail. We may use the information to customise the website according to your interests.</li>
</ul>
We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure we have put in suitable measures.
<br/><br/>
<b>How we use cookies</b><br/>
A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyze web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.
We use traffic log cookies to identify which pages are being used. This helps us analyze data about webpage traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.
Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.
You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.
<br/><br/>
<b>Controlling your personal information</b>
<br/>
If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to or emailing us at
<br/>
We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen.
If you believe that any information we are holding on you is incorrect or incomplete, please write to Ward 15 indira para, shanti nagar bhilai Durg CHATTISGARH 490021 . or contact us at or as soon as possible. We will promptly correct any information found to be incorrect.
<br/><br/>

<h3>Shipping & Delivery Policy</h3><br/>
Last updated on Aug 30th 2024<br/><br/>
For International buyers, orders are shipped and delivered through registered international courier companies and/or International speed post only. For domestic buyers, orders are shipped through registered domestic courier companies and /or speed post only. Orders are shipped within 8-14 days or as per the delivery date agreed at the time of order confirmation and delivering of the shipment subject to Courier Company / post office norms. Blue Pearl is not liable for any delay in delivery by the courier company / postal authorities and only guarantees to hand over the consignment to the courier company or postal authorities within 8-14 days rom the date of the order and payment or as per the delivery date agreed at the time of order confirmation. Delivery of all orders will be to the address provided by the buyer. Delivery of our services will be confirmed on your mail ID as specified during registration. For any issues in utilizing our services you may contact our helpdesk on or

<br/><br/>

<h3>Cancellation & Refund Policy</h3><br/>
Last updated on Aug 30th 2024<br/><br/>
Blue Pearl believes in helping its customers as far as possible, and has therefore a liberal cancellation policy. Under this policy:
<ul>
<li>	Cancellations will be considered only if the request is made within same day of placing the order. However, the cancellation request may not be entertained if the orders have been communicated to the vendors/merchants and they have initiated the process of shipping them.</li>
<li>	Blue Pearl does not accept cancellation requests for perishable items like flowers, eatables etc. However, refund/replacement can be made if the customer establishes that the quality of product delivered is not good.</li>
<li>	In case of receipt of damaged or defective items please report the same to our Customer Service team. The request will, however, be entertained once the merchant has checked and determined the same at his own end. This should be reported within same day of receipt of the products.</li>
<li>	In case you feel that the product received is not as shown on the site or as per your expectations, you must bring it to the notice of our customer service within same day of receiving the product. The Customer Service Team after looking into your complaint will take an appropriate decision.</li>
<li>	In case of complaints regarding products that come with a warranty from manufacturers, please refer the issue to them.</li>
<li>	In case of any Refunds approved by the Blue Pearl, it’ll take 6-8 days for the refund to be processed to the end customer.</li>
</ul>
<br/><br/>

{/*                     */}

<h3>Return Policy</h3><br/>

<b>Effective Date: </b> <br/>
At Maviinci, we are dedicated to providing high-quality custom-tailored clothing. If you are not completely satisfied with your order, please review our return policy outlined below.
<br/><br/><b>1. Eligibility for Returns</b><br/>
Returns are accepted under the following conditions:<br/>
<li>	Not as per Your Chosen Style/Fabric: If the item received does not match the specifications (style or fabric) you selected during the ordering process.</li>
<li>	Damaged Product: If your item arrives damaged or defective, please report it promptly.</li>
<li>	Late Delivery: If your order is delivered more than three (3) days after the scheduled delivery date.</li>
<li>	After Two Alterations: If you have made two alterations to the item and it still does not meet your expectations based on the agreed-upon specifications.</li>

<br/><br/>
<b>2. Return Window</b>
<br/>
To initiate a return, you must report the issue to us within 24 hours of receiving your order. Please contact our customer service team with your order details and the reason for the return.
<br/><br/>
<b>3. Refund Process</b>
<br/>
If your return request is approved, you will receive a refund of the purchase price, subject to a 2% deduction from the total amount. The refund will be processed using the same payment method you used for your original purchase.
<br/><br/>
<b>4. How to Initiate a Return</b> <br/>
To start the return process, please follow these steps:
<br/>
<li>Contact our customer service team at care@maviinci or +91-9876543210 within 24 hours of receiving your order.</li>
<li>Provide your order number and a description of the issue.</li>
<li>Follow the instructions provided by our team for returning the item.</li>
<br/><br/>
<b> 5. Non-Returnable Items</b> <br/>
Certain items may be non-returnable, including those that have been altered beyond the allowed two alterations or custom items made to your specifications that do not meet the eligibility criteria outlined above.
<br/><br/>
<b>6. Shipping Costs</b> <br/>

If the return is due to a defect or error on our part, we will cover the return shipping costs. In cases where the return is not due to our error, you will be responsible for the return shipping fees.
<br/><br/>
<b>7. Changes to the Return Policy</b> <br/>
Maviinci reserves the right to modify this Return Policy at any time. Any changes will be effective immediately upon posting on our website. We encourage you to review this policy periodically for any updates.

<br/>

<h3>Contact us</h3>
Last updated on Aug 30th 2024<br/><br/>
You may contact us using the information below:<br/>
Merchant Legal entity name: Blue Pearl<br/>
Registered Address: Ward 15 indira para, shanti nagar bhilai Durg CHATTISGARH 490021<br/>
Operational Address: Ward 15 indira para, shanti nagar bhilai Durg CHATTISGARH 490021<br/>
Email	: isupport@bluepearlindustries.com <br/>
phone	: +91-8871081395<br/><br/><br/><br/>

    </div> );
}

export default Terms;