import './measurements.css'


import mbg1 from '../assets/mbg1.jpg'
import mbg2 from '../assets/mbg2.jpg'
import mbg3 from '../assets/mbg3.jpeg'
import mbg4 from '../assets/mbg4.jpg'
import Measurement_hover from './hoverComp'
import { useState } from 'react'
import { RIGHT } from 'react-swipeable'

function Measurement_sel_page({typeM, setSection, setUseSection, isMobile}) {

    
    const [style1, setStyle1] = useState({display:"none"})
    const [style2, setStyle2] = useState({class:"m_hook_arrow_right"})

    const [index, setIndex] = useState(0)
    
    const setHover = (t, q = 1)=>{
        setIndex(t)
        if(q && !isMobile){
            if(t === 0 || t === 2){
                
                setStyle2({
                    class:"m_hook_arrow_right",
                    style:{
                        marginTop : t === 0 ? "100px" : "250px"
                    }
                })
                setStyle1(
                    {
                        opacity:1,
                        left:"-22vw",
                    }
                )
            } else{
                
                setStyle1({
                    right:"-22vw",
                    opacity:1,
                    flexDirection:"row-reverse"
                })
                setStyle2({
                    style:{
                        marginTop : t === 1 ? "100px" : "250px"
                    },
                    class:"m_hook_arrow_left"
                })
            }
        } else {
            
            setStyle1({display:"none"})
            setStyle2({})
        }
        
    }
    return (
    
    <div className={`${!isMobile ? 'both_center':''} FIT_W FIT_H`}
      
    >
        {!isMobile && <div className='title-font1' style={{position:"", top:"-80px", height:"fit-content", "width":"100%", textAlign:"center"}}> Measurements made Easy for you </div>}
    <div className={`m_page0`}>
        
        <div className='m_page0_wrapper'>
            
            <div className="m_page0_row" style={!isMobile?{marginBottom:"20px"}:{}}>
                <div className="m_page0_row_card"
                    style = {{backgroundImage:`url(${mbg3})`, backgroundSize:"cover"}}
                    type='button'
                    onClick={()=>{
                        window.history.pushState( 'dummy', document.title, window.location.href )
                        if(typeM){
                            setSection(1)
                        }else{
                            setUseSection(1)
                        }
                    }}

                    onMouseEnter={()=>{
                        setHover(0)
                    }}
                    onMouseLeave={()=>{
                        setHover(0, 0)
                    }}
                >  
                    
                    <div className='m-home-mask'>  </div>
                    <div className='m_page0_des FIT_W FIT_H both_center'> 
                        <div className='txt-font' >Custom Measurements</div>
                        {isMobile && <div className='txt-font1'> Measuring for your perfect fit has never been easier.
                        Follow simple steps and get Measured easy at your home</div>}
                    </div>
                    
                </div>

                <div className="m_page0_row_card"
                    style = {{backgroundImage:`url(${mbg2})`, backgroundSize:"cover"}}
                    type='button'
                    onClick={()=>{
                        window.history.pushState( 'dummy', document.title, window.location.href )
                        if(typeM){
                            setSection(2)
                        }else{
                            setUseSection(2)
                        }
                    }}

                    onMouseEnter={()=>{
                        setHover(1)
                    }}
                    onMouseLeave={()=>{
                        setHover(1, 0)
                    }}
                >   
                    <div className='m-home-mask'>  </div>
                    <div className='m_page0_des FIT_W FIT_H both_center'> 
                        <div className='txt-font' >Choose From Size Chart</div>
                        {isMobile && <div className='txt-font1'> Easily find your ideal fit with our
                        detailed size chart. </div>}
                    </div>
                </div>
                
            </div>
            <div className="m_page0_row">
                <div className="m_page0_row_card"
                    style = {{backgroundImage:`url(${mbg1})`, backgroundSize:"cover"}}
                    type='button'
                    onClick={()=>{
                        window.history.pushState( 'dummy', document.title, window.location.href )
                        if(typeM){
                            setSection(3) 
                        }else{
                            setUseSection(3)
                        }
                    }}
                    onMouseEnter={()=>{
                        setHover(2)
                    }}
                    onMouseLeave={()=>{
                        setHover(2, 0)
                    }}
                >   
                    <div className='m-home-mask'>  </div>
                    <div className='m_page0_des FIT_W FIT_H both_center'> 
                        <div className='txt-font' > Saved Measurements </div>
                        {isMobile && <div className='txt-font1'> Choose from your Saved measurements, from your previous orders and we wil take care of the rest </div>}
                    </div>
                </div>

                <div className="m_page0_row_card"
                    style = {{backgroundImage:`url(${mbg4})`, backgroundSize:"cover"}}
                    type='button'
                    onClick={()=>{
                        window.history.pushState( 'dummy', document.title, window.location.href )
                        if(typeM){
                            setSection(4)
                        }else{
                            setUseSection(4)
                        }
                    }}
                    onMouseEnter={()=>{
                        setHover(3)
                    }}
                    onMouseLeave={()=>{
                        setHover(3, 0)
                    }}
                >   
                    <div className='m-home-mask'>  </div>
                    <div className='m_page0_des FIT_W FIT_H both_center'> 
                        <div className='txt-font' > Clone a cloth </div>
                        {isMobile && <div className='txt-font1'> Do you have a cloth piece that fits you perfectly ? provide us the piece and we will ensure your orde to fit exactly like your cloth </div>}
                    </div>
                </div>
                
            </div>
        </div>
        {!isMobile && <Measurement_hover
            style2 = {style2}
            style1 = {style1}
            index = {index}
        />}
    </div> 
    </div>
    );
}

export default Measurement_sel_page;