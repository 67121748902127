import { useEffect, useState } from "react";

import {signin, signinGoogle} from '../Firebase/auth'
import { getAuth } from "firebase/auth";

import google from '../assets/icon/google1.png'

import firebase from "../firebase";
import { useLocation, useNavigate } from "react-router-dom";

import { MuiOtpInput } from 'mui-one-time-password-input'

import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { auth } from '../firebase';

import login_vid from '../assets/login_vid.mp4'

const backendURL = "https://suits-backend.vercel.app"

function Login() {
    const navigate = useNavigate()
    const location = useLocation()
    const [redirect, setRedirect] = useState("/")
    
    
    const [isMobile, setIsMobile] = useState(window.innerWidth < 480);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 480);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [id, setId] = useState('');
    const [otp, setOtp] = useState('');

    const [state, setState] = useState(0)

    const [emailOTP, setEmailOTP] =useState("456378")
    const [type, setType] = useState(0)

    const [counter, setCounter] = useState(-1);

    useEffect(()=>{
        console.log("State is changing , ", state)
    }, [state])

    useEffect(()=>{
        if(location && location.state && location["state"]["from"]){
            setRedirect(redirect+location.state["from"])
            
            // console.log(redirect+location.state["from"])
        }
    }, [])

    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
      }, [counter]);

    const generateRecaptcha = () => {
        if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha', {
                'size': 'invisible',
                'callback': (response) => {
                  // reCAPTCHA solved, allow signInWithPhoneNumber.
                  // ...
                }
              });
        }
        
    }

    const sendEmailOTP = async()=>{
        try{
            setState(1)

            const o = Math.random().toString().substr(2, 6)
            setEmailOTP(o)
            const headers = {
                "Content-Type" : "application/json"
            }
        
            const params ={
                "email" : id,
                "otp": o
            }
            const resp = await fetch(`${backendURL}/sendOtp`, { method: 'POST', headers:headers,body:JSON.stringify(params)});
            const data = await resp.json();


        }catch(e){
            console.log(e)
        }
    }

    
    const handleChange = (newValue) => {
        console.log(newValue)
        setOtp(newValue)
    }

    const handleSend = () => {
        // event.preventDefault();
        // setHasFilled(true);
        console.log("Came in here")
        generateRecaptcha();
        let appVerifier = window.recaptchaVerifier;
        signInWithPhoneNumber(auth, `+918977664489`, appVerifier)
          .then((confirmationResult) => {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            console.log("otp sent")
            setState(1)
            window.confirmationResult = confirmationResult;
          }).catch((error) => {
            // Error; SMS not sent
            console.log(error);
          });
    }

    const verifyOtp = (a) => {
        let otp = a;
        setOtp(otp);
    
        if (otp.length === 6) {
          // verifu otp
          let confirmationResult = window.confirmationResult;
          confirmationResult.confirm(otp).then((result) => {
            // User signed in successfully.
            let user = result.user;
            console.log(user);
            alert('User signed in successfully');
            // ...
          }).catch((error) => {
            // User couldn't sign in (bad verification code?)
            // ...
            alert('User couldn\'t sign in (bad verification code?)');
          });
        }
    }

    const signinWithToken = async()=>{
        try{
            const headers = {
                "Content-Type" : "application/json"
            }
        
            const params ={
                "email" : id
            }
            const resp = await fetch(`${backendURL}/customToken`, { method: 'POST', headers:headers,body:JSON.stringify(params)});
            const data = await resp.json();

            const u=await auth
            .signInWithCustomToken(data.customToken) 

            navigate(redirect)
        } catch(e){ 
            console.log(e)
        }
    }

    return ( 
    <div className="auth">
        { !isMobile && <div style={{"width":"60vw", "height":"100svh", "position":"absolute", backgroundColor:"white"}} className="mobile_vid_login">
                        <video className='' autoPlay muted playsInline loop style={{"width":"100%", "height":"100%", "objectFit":"cover"}}>
                        <source src={login_vid} /> 
                        </video>
        </div>}
        <div className={isMobile ? "login hori_center" : "login"}

                
        >
            <div style={{width:"100%", height:'100%', position:"relative", display:"flex", gap:"10px", flexDirection:"column"}}>
            <div className="title-font1">Login</div>

            

            <div>
            <img src={google} className="google_logo"
                type="button"
                onClick={()=>{
                    signinGoogle().then(()=>{
                        navigate(redirect)
                    }).catch((error)=>{console.log(error)})
                }}
            />
            </div>

            <div className="txt-font" style={{opacity:"0.7", zIndex:"10001"}}>or</div>

            <div className="login_type hori_center"> 
                <label className="login_t_left" style={ type ===0 ? {color:"white", backgroundColor:"#14213D"}: {}}
                    onClick={()=>{if(state === 0){setType(0)}}} type="button"
                    
                >Email</label>
                <label className="login_r_left" style={ type ===1 ? {color:"white", backgroundColor:"#14213D", opacity:"0.4"}: { opacity:"0.4"}} 
                    onClick={()=>{if(state === 0){
                        // setType(1)
                    }}} type="button"
                    
                >Phone Number</label>
            </div>

            <div style={{textAlign:"left", zIndex:"100"}} className="hori_center"> <b>{type ===0 ? "Email ID" : "Phone number"} </b></div>
            <div style={{position:'relative', zIndex:"100"}}>
            {type === 1 && <label style={{position:"absolute", width:"fit-content", margin:"0", bottom:"15px", left:"4px"}} >+91</label>}
            <input placeholder={type ===0 ? "ex :  ilovemaviinci@gmail.com" : "9000000000"} 
                style={ {paddingLeft:"10%"}}
                disabled = {state === 0 ? false : true}
                className="L-email hori_center" onChange={(e)=>{setId(e.target.value);console.log(id)}} />
            </div>
            {state ===0 && <button className="Butt1 hori_center"

                    style={{ borderRadius:'20px', zIndex:"100" }}
                    disabled = {(id && id.length > 0 ) ? false : true}
                    onClick={async()=>{
                        setState(1)
                        setCounter(60)
                        if(id && id.length > 0){
                            if(type === 0){
                                await sendEmailOTP()
                            } else{
                                handleSend()
                            }
                            
                        } 
                        
                    }}
            >
                 <span>Get OTP </span>
            </button>}

            {(isMobile && state === 1) && <div className="otp_inp"
                // style={state === 1 ? {"transform":"translateY(110%)" }:{"transform":"translateY(0)"}}
            >
                
                <div><b>Enter OTP</b></div>
                <MuiOtpInput value={otp} onChange={handleChange} length={6}/>
                <div>Resend {counter>0 ? `in ${counter}s` : ""}</div>
                <br/>
                <button className="Butt1 hori_center"
                    onClick={async()=>{
                        if(type === 0){
                            console.log(emailOTP, otp)
                            if(emailOTP == otp){
                                await signinWithToken()
                                
                            }
                        } else{

                        }
                    }}
                > <span> Login </span> </button>
            </div>}
            {/* <img src={google} className="google_logo hori_center"
                type="button"
                onClick={()=>{
                    signinGoogle().then(()=>{
                        navigate(redirect)
                    }).catch((error)=>{console.log(error)})
                }}
            />
            
            <input placeholder="Email" className="L-email" onChange={(e)=>{setPhone(e.target.value);console.log(e.target.value)}} />

            <input placeholder="Password" className="L-pass" onChange={(e)=>{setOtp(e.target.value);console.log(e.target.value)}} />

            <button className="Butt1 hori_center"
                // disabled = {true}
                onClick={(e)=>{
                    handleSend()
                }}
            ><span>Log in</span></button> */}

            {!isMobile && <div className="otp_inp"
                style={state === 1 ? {"transform":"translateY(110%)" }:{"transform":"translateY(0)"}}
            >
                <div className = "otp_cover" style={state === 0 ? {display:"block"}:{display:"none"}} >  </div>
                <div><b>Enter OTP</b></div>
                <MuiOtpInput value={otp} onChange={handleChange} length={6}/>
                <div>Resend {counter>0 ? `in ${counter}s` : ""}</div>
                <br/>
                <button className="Butt1 hori_center"
                    onClick={async()=>{
                        if(type === 0){
                            console.log(emailOTP, otp)
                            if(emailOTP == otp){
                                await signinWithToken()
                                
                            }
                        } else{

                        }
                    }}
                > <span> Login </span> </button>
            </div>}
        </div>
        
        <div id="recaptcha"></div>
        </div>
    </div> 
    );
}

export default Login;